import { Route, Routes } from "react-router-dom"
import App from "../App"
import AuthRoute from '../authRoute';
import { AppRoutes } from "./allRoutes";

function MobileRoutes() {
    return (
        <Routes>
            <Route path="/" element={<App />} />
            {
                AppRoutes.map((route, routeIndex) => (
                    route.getAllPaths().map((path, pathIndex) => {
                        let element = route.element;
                        if (route.authType) {
                            element = (
                                <AuthRoute type={route.authType} approved={route.approvedUsers}>
                                    {route.element}
                                </AuthRoute>)
                        }

                        return (
                            <Route
                                key={`Key${routeIndex}-${pathIndex}`}
                                path={path}
                                element={element} />
                        );
                    }
                    )))
            }
        </Routes>
    )
}

export default MobileRoutes;
