import { ListItem, Grid, Typography, List, Card, CardHeader, Box } from "@mui/material";
import { NearestSalesPoint, OrderDestination } from "orderme-api-integration-client";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { PointInfo } from "./pointInfo";
import { useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { deliverToSalesPoint } from "../../redux/reducers/pickupReducer";
import { useRouteNavigator } from "../../routes/useRouteNavigator";

interface ChoosePointsProps {
  filteredDeliveryDetails: NearestSalesPoint[],
  isPurchase: boolean
}

export function ChoosePoints(props: ChoosePointsProps) {
  const { filteredDeliveryDetails, isPurchase } = props;
  const [selectedSalesPoint, setSelectedSalesPoint] = useState<NearestSalesPoint>();
  const routeNavigator = useRouteNavigator();
  const dispatch = useAppDispatch();

  const clickPoint = (point: NearestSalesPoint) => {
    if (isPurchase) {
      dispatch(deliverToSalesPoint({ salesPointId: point.value.id }));
      routeNavigator.goToOrderConfirmation({ replace: true })
    }
    else {
      if (appStyleMode === AppStyle.Mobile) {
        routeNavigator.goToSalesPoint({ state: point });
      }
      else {
        if (selectedSalesPoint !== point)
          setSelectedSalesPoint(point);
        else
          setSelectedSalesPoint(null);
      }
    }
  }

  return (
    <>
      <List className={(appStyleMode === AppStyle.Mobile ? "sales-points-list" : null)}>
        {filteredDeliveryDetails?.map((deliveryOption, index) => {
          return (
            <Grid container sx={{ px: "16px", py: "5px" }}>
              <ListItem sx={{ p: "0px", }}
                button
                onClick={() => clickPoint(deliveryOption)}
                key={index}>
                <Card
                  sx={{
                    width: "100%",
                    borderRadius: deliveryOption === selectedSalesPoint ? "4px 4px 0px 0px" : "4px"
                  }}>
                  <CardHeader title=
                    {
                      <Grid container justifyContent="space-between" sx={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                        <Box>
                          <Typography>{deliveryOption.value.name}, {deliveryOption.value.city}</Typography>
                          <Typography sx={{ fontSize: "12px" }}>{deliveryOption.value.address}</Typography>
                        </Box>
                        {deliveryOption !== selectedSalesPoint && (
                          <Grid sx={{ display: "flex", alignItems: "center", fontSize: "30px", }}>
                            {deliveryOption.distanceInMeters && <Typography sx={{ color: "#1f4a8f" }}>{(deliveryOption.distanceInMeters / 1000).toFixed(2)}km</Typography>}
                            <ArrowForwardIosIcon sx={{ color: "#dbdbdb", height: "17px" }} />
                          </Grid>
                        )}
                      </Grid>
                    } />
                </Card>
              </ListItem>
              {deliveryOption === selectedSalesPoint && (
                <PointInfo salesPoint={deliveryOption} />
              )}
            </Grid>
          );
        })}
      </List>
    </>);
}
