import { useEffect, useState } from 'react';
import { updateClientAccessToken, updateClientLanguage, updateClientPlatform } from '../helpers/client';
import { assignCustomerDetails } from '../helpers/customerHelper';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { RootState } from '../redux/store';
import { clearCustomer } from '../redux/reducers/customerReducer';
import tokenRefresher from '../helpers/tokenRefresher';
import { login } from '../redux/reducers/authReducer';
import { addServicesFromStorage, refreshCartServices } from '../helpers/cartVerifier';
import { loadShortDataLinks } from '../redux/reducers/linksReducer';

const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const dispatch = useAppDispatch();
    const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);
    const isUserLoggedIn = useAppSelector((state: RootState) => state.authState.isLoggedIn);
    const accessToken = useAppSelector((state: RootState) => state.authState.accessToken);
    const customerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
    const servicesInBasket = useAppSelector((state: RootState) => state.cartState.servicesInBasket);
    const linksLoaded = useAppSelector((state: RootState) => state.linksState.loaded);

    tokenRefresher.setupInterceptors();

    useEffect(() => {
        dispatch(loadShortDataLinks());
        updateClientLanguage();
        updateClientPlatform();
    }, []);

    useEffect(() => {
        if (accessToken) {
            updateClientAccessToken(accessToken);
            assignCustomerDetails().then(() => {
                setIsUserInfoLoaded(true);
            }).catch(err => {
                console.log(err);
                setIsUserInfoLoaded(true);
            });
        }
        else {
            updateClientAccessToken();
            setIsUserInfoLoaded(true);
        }

    }, [accessToken]);

    useEffect(() => {
        if (isUserInfoLoaded && servicesInBasket.length === 0)
            addServicesFromStorage();
    }, [isUserInfoLoaded]);

    useEffect(() => {
        if (!isUserLoggedIn) {
            if (customerInfo.id) {
                dispatch(clearCustomer());
            }
        }
    }, [isUserLoggedIn]);

    useEffect(() => {
        if (customerInfo.id && !isUserLoggedIn) {
            dispatch(login());
        }
        refreshCartServices();
    }, [customerInfo]);

    const isLoaded = () => isUserInfoLoaded && linksLoaded;

    return isLoaded() && children
};

export default AuthProvider;