import { useEffect, useState } from "react";
import { Box, Divider, Grid, List, ListItem } from "@mui/material";
import { ServiceItem } from "orderme-api-integration-client";
import { ServiceClient } from "../../helpers/client"
import { Linear } from "../progressBars/linear"
import { ProductCard } from "../productCard/productCard"
import { animated, useTransition } from "@react-spring/web";
import { getUserId } from "../../helpers/customerHelper";
import { SelectedGroupServicesProps } from "../../interfaces/interfaces";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { isDevEnvironment } from "../../env";
import { HtmlViewer } from "../../controls/html/htmlViewer";

export function ServicesList(props: SelectedGroupServicesProps) {
    const { serviceGroup, serviceCategory } = props;
    const servicesClient: ServiceClient = new ServiceClient();
    const [serviceItems, setServiceItems] = useState<ServiceItem[]>();

    useEffect(() => {
        setServiceItems(undefined);
        if (serviceGroup && serviceGroup.id) {
            loadGroupServices();
        }
    }, [serviceGroup]);

    useEffect(() => {
        if (!serviceGroup || !serviceCategory)
            return;

        try {
            if (appStyleMode === AppStyle.Paulini && !isDevEnvironment()) {
                gtag("event", "select_content", {
                    content_type: serviceCategory.name,
                    content_id: serviceGroup.name + " | " + serviceCategory.name
                });
            }
        }
        catch (ex) {
            console.error(ex);
        }
    }, [serviceGroup, serviceCategory]);

    const loadGroupServices = () => {
        servicesClient.getGroupServices(serviceGroup.id, getUserId()).then((response) => {
            setServiceItems(response);
        }).catch((err) => console.log(err));
    }

    const transitions = useTransition(serviceItems, {
        from: { transform: 'translate3d(0,-250px,0)', opacity: 0 },
        enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    })

    return (
        <>
            <Grid className={appStyleMode === AppStyle.Mobile ? "services-list" : null} >
                {serviceGroup.description && (
                    <Box>
                        <HtmlViewer content={serviceGroup.description} />
                        <Divider />
                    </Box>)
                }

                {serviceItems === undefined ? (<Linear />) : (
                    <List sx={{ overflow: "auto" }} >
                        {transitions((props, item) => (
                            <animated.div style={props}>
                                <ListItem sx={{ display: "flex", justifyContent: "center" }}>
                                    <ProductCard item={item} backgroundColor="paulini.blue" fontSize={15} fontColor="white" buttonSize={40} />
                                </ListItem>
                            </animated.div>
                        ))}
                    </List>
                )}
            </Grid>
        </>
    );
}