import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { InfoFeed, ShortDataLinkType } from "orderme-api-integration-client";
import { Linear } from "../progressBars/linear";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { HtmlViewer } from "../../controls/html/htmlViewer";
import { InfoFeedClient } from "../../helpers/client";
import { useParams } from "react-router";
import { useRouteNavigator } from "../../routes/useRouteNavigator";
import { NewsItemRoute, NewsRoute } from "../../routes/allRoutes";
import useGetParameter from "../../hooks/useGetParameter";
import { RouterParam } from "../../helpers/routerParam";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { generateSlug } from "../../helpers/slugGenerator";

export function NewsItem() {
    const routeNavigator = useRouteNavigator();
    const infoFeedClient = new InfoFeedClient();
    const params = useParams<"title">();
    const idParam = useGetParameter(RouterParam.Id);
    const [infoFeedId, setInfoFeedId] = useState<string>();
    const [infoFeed, setInfoFeed] = useState<InfoFeed>();
    const links = useAppSelector((state: RootState) => state.linksState.news);

    useEffect(() => {
        const link = links.find(link => link.id === idParam);
        if (link) {
            setInfoFeedId(link.dataId);
        }
        else
            routeNavigator.goHome();
    }, [idParam])

    useEffect(() => {
        if (infoFeedId)
            getInfoFeed(infoFeedId);
        else
            setInfoFeed(null);

    }, [infoFeedId])

    useEffect(() => {
        if (infoFeed) {
            if (params.title !== generateSlug(infoFeed.title))
                routeNavigator.goToNewsItem(infoFeed.id, infoFeed.title);
        }

    }, [infoFeed])

    const getInfoFeed = async (id: string) => {
        try {
            setInfoFeed(await infoFeedClient.get(id));
        } catch (error) {
            console.error(`Error fetching news by ID=${id}:`, error);
            setInfoFeed(null);
            routeNavigator.goHome();
        }
    };

    return (
        <Grid container direction="column" alignItems="center">
            {!infoFeed && (<Linear />)}
            <BreadcrumbsBar paths={[{ path: NewsRoute, isLink: true }, { path: NewsItemRoute, title: infoFeed?.title }]} />
            {infoFeed &&
                <Grid sx={{
                    maxWidth: "1000px",
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px"
                }}>
                    <HtmlViewer content={infoFeed.description} />
                </Grid>
            }
        </Grid>
    );
}