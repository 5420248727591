import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { NavBar } from "../appBars/navBar/navBar"
import { CompanyInfo } from "orderme-api-integration-client";
import { Linear } from "../progressBars/linear";
import { useTranslation } from 'react-i18next';
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { HtmlViewer } from "../../controls/html/htmlViewer";
import { InfoFeedClient } from "../../helpers/client";
import { AboutUsRoute } from "../../routes/allRoutes";

export function AboutUs() {
    const infoFeedsClient = new InfoFeedClient();
    const [companyInfo, setCompanyInfo] = useState<CompanyInfo>();
    const { t } = useTranslation('info');

    useEffect(() => {
        getCompanyInfo();
    }, [])

    const getCompanyInfo = () => {
        infoFeedsClient.getCompanyInfo().then(response => {
            setCompanyInfo(response);
        }).catch(err => console.log(err));
    }

    return (
        <Grid container direction="column" alignItems="center">
            <NavBar barTitle={t('aboutUs')} />
            {!companyInfo && (<Linear />)}
            <BreadcrumbsBar paths={[{ path: AboutUsRoute }]} />
            {companyInfo &&
                <Grid sx={{
                    maxWidth: "1000px",
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px"
                }}>
                    <HtmlViewer content={companyInfo.description} />
                </Grid>
            }
        </Grid>
    );
}