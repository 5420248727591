import { Grid, Typography, Box, Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { NearestParcelLocker } from "orderme-api-integration-client";
import { useTranslation } from "react-i18next";

interface ParcelLockerSelectionProps {
  filteredDeliveryDetails: NearestParcelLocker[],
  value: NearestParcelLocker;
  onSelect(parcelLocker: NearestParcelLocker): void;
}

export function ParcelLockerSelection(props: ParcelLockerSelectionProps) {
  const { t } = useTranslation('salesPoint');
  const { filteredDeliveryDetails, onSelect, value } = props;

  const filterOptions = createFilterOptions({
    stringify: (option: NearestParcelLocker) => option.value.name + option.value.address
  });


  return (
    <>{filteredDeliveryDetails &&
      <>
        <Autocomplete
          filterOptions={filterOptions}
          sx={{
            width: "100%",
            m: "15px",
            '& fieldset': { borderRadius: "15px" }
          }}
          ListboxProps={{ style: { maxHeight: "300px" } }} // Adjust the height to control visible items
          value={value}
          blurOnSelect
          options={filteredDeliveryDetails}
          onChange={(_, option) => onSelect(option)}
          getOptionLabel={(option) => option.value.name}
          renderInput={(params) => <TextField {...params} label={t("selectParcelLocker")} />}
          renderOption={(props, parcelLocker) => {
            const { key, ...optionProps } = props;
            return (
              <Box key={key} component="li" {...optionProps}>
                <Grid container justifyContent="space-between" sx={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                  <Grid container sx={{ alignItems: "center" }}>
                    <Typography sx={{ pr: "10px" }}>{parcelLocker.value.name}</Typography>
                    <Typography sx={{ fontSize: "12px", color: "grey" }}>{parcelLocker.value.address}</Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", fontSize: "30px" }}>
                    {parcelLocker.distanceInMeters && <Typography sx={{ color: "#1f4a8f" }}>{(parcelLocker.distanceInMeters / 1000).toFixed(2)}km</Typography>}
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        />
      </>
    }
    </>);
}
