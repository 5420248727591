import { Link, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { RouteConfig } from "../../../routes/routeConfig";
import { useRouteNavigator } from "../../../routes/useRouteNavigator";

interface HomeBarLinkProps {
    link: RouteConfig;
    text: string;
    disableMargin?: boolean;
}

export const HomeBarLink: React.FC<HomeBarLinkProps> = ({ link, text, disableMargin }) => {
    const routeNavigator = useRouteNavigator();
    const isSelected = useLocation().pathname.endsWith(link.getPath());

    const handleClick = () => {
        routeNavigator.goTo(link);
    }

    return (
        <Link underline="none" sx={{
            color: "white",
            mx: disableMargin ? "0px" : "40px",
            borderBottomWidth: isSelected && "5px",
            mt: isSelected && "5px",
            borderBottomStyle: "solid",
            borderBottomColor: "paulini.orange"
        }} component="button" onClick={handleClick}>
            <Typography sx={{ textTransform: "uppercase" }}>{text}</Typography>
        </Link>
    );
}