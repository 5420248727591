import { Toolbar, Grid, SxProps, Theme } from "@mui/material";
import { roundDoubleNumbers } from "../../../helpers/currency"
import { BottomAppBar, CartBartTypography } from "../appBarSyles"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { cartWorth, getFinalPrice } from "../../../helpers/costCounter";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../../redux/hooks";
import { CartBarBadgeIcon } from "../../shoppingCart/cartBarBadgeIcon";
import { useRouteNavigator } from "../../../routes/useRouteNavigator";

interface CartBarProps {
    barText?: string;
    open?: () => void;
    isDisabled?: boolean;
    isPurchase?: boolean;
    includeDelivery?: boolean;
    sx?: SxProps<Theme>;
    className?: string;
    position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
}

export function CartBar(props: CartBarProps) {
    const routeNavigator = useRouteNavigator();
    const { t } = useTranslation('payment');
    const {
        barText = t('cart'),
        open = routeNavigator.goToCart,
        isPurchase = false,
        sx = [],
        isDisabled = false,
        includeDelivery = false
    } = props;

    const getPrice = () => {
        if (isPurchase) {
            return roundDoubleNumbers(getFinalPrice(includeDelivery));
        }

        return roundDoubleNumbers(cartWorth());
    }

    const price: string = useAppSelector(() => getPrice());

    const onClick = () => {
        if (isDisabled)
            return;

        open();
    }

    return (

        <BottomAppBar
            sx={[
                {
                    cursor: !isDisabled && "pointer",
                    backgroundColor: isDisabled && "#f0f0f0",
                    ":hover": {
                        backgroundColor: !isDisabled && "paulini.orangeHover",
                        boxShadow: !isDisabled && "1px 1px 5px grey"
                    }
                },
                ...(Array.isArray(sx) ? sx : [sx])]}
            className={props.className}
            position={props.position}
            color="primary"
            onClick={() => onClick()}>
            <Toolbar>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item display="flex" alignItems="center">
                        <CartBarBadgeIcon />
                        <CartBartTypography> {price} €</CartBartTypography>
                    </Grid>
                    <Grid item display="flex" alignItems="center">
                        <CartBartTypography>{barText}</CartBartTypography>
                        <KeyboardArrowRightIcon sx={{ ml: "10px" }} />
                    </Grid>
                </Grid>
            </Toolbar>
        </BottomAppBar>
    );
}