import { useTranslation } from "react-i18next";
import { CartBar } from "./cartBar";

export interface BottomCartBarProps {
    open?: () => void;
    /** True if purchase is already started */
    isPurchase?: boolean;
    /** True if it's final step before payment. Default value is false. */
    isConfirmation?: boolean;
    isDisabled?: boolean;
}

export function BottomCartBar(props: BottomCartBarProps) {
    const { t } = useTranslation('payment');
    const {
        isPurchase = false,
        isConfirmation = false,
        isDisabled = false,
    } = props;

    return (<CartBar
        className="app-bottom-bar"
        barText={isConfirmation ? t('pay') : isPurchase ? t('continue') : t('cart')}
        isPurchase={isPurchase}
        isDisabled={isDisabled}
        includeDelivery={isConfirmation}
        open={props.open} />)
}