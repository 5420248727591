import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NearestSalesPoint } from "orderme-api-integration-client";
import { NavBar } from "../appBars/navBar/navBar"
import { PointInfo } from "./pointInfo";
import { useRouteNavigator } from "../../routes/useRouteNavigator";

export function SelectedPoint() {
    const routeNavigator = useRouteNavigator();
    const location = useLocation();
    const salesPoint = location.state as NearestSalesPoint;

    useEffect(() => {
        if (!salesPoint) {
            routeNavigator.goBack();
        }
    }, [])

    return (
        <>
            <NavBar barTitle={salesPoint?.value.name} />
            {salesPoint && (<PointInfo salesPoint={salesPoint}></PointInfo>)}
        </>
    );
}