import { useEffect } from "react";
import { Grid, Typography, Breadcrumbs, Link, SxProps, Theme, } from "@mui/material";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { updateDocumentTitle } from "../../helpers/documentHelper";
import { useRouteNavigator } from "../../routes/useRouteNavigator";
import { RouteConfig } from "../../routes/routeConfig";

export interface BreadcrumbsPath {
    path: RouteConfig;
    customPath?: string;
    title?: string;
    isLink?: boolean;
}
export interface BreadcrumbsBarProps {
    paths: BreadcrumbsPath[];
    sx?: SxProps<Theme>;
}

export function BreadcrumbsBar(props: BreadcrumbsBarProps) {
    const routeNavigator = useRouteNavigator();
    const { sx = [], paths = [] } = props;

    useEffect(() => {
        if (paths?.length > 0) {
            const lastItem = paths.at(-1);
            updateDocumentTitle(lastItem.title ?? lastItem.path.title);
        }
    }, [paths]);

    const getContent = () => {
        if (appStyleMode === AppStyle.Mobile)
            return null;

        return getWebsiteContent();
    }

    const getWebsiteContent = () => {
        return (
            <Grid
                sx={[
                    {
                        width: "100%",
                        color: "white",
                        maxWidth: "1000px",
                        mb: "10px"
                    },
                    ...(Array.isArray(sx) ? sx : [sx])
                ]}>
                {paths && (
                    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon sx={{ color: "white" }} fontSize="medium" />} >
                        <Link key="breadcrumbs-paulini" underline="hover" color="white" component="button" onClick={() => routeNavigator.goHome()}>
                            PAULINI
                        </Link>
                        {
                            paths.map((path, index) => {
                                const name = path.title ?? path.path.title;
                                const key = "breadcrumbs-" + index;
                                return path.isLink ?
                                    (<Link key={key} underline="hover" color="white" component="button"
                                        onClick={() => path.customPath ? routeNavigator.goToPath(path.customPath) : routeNavigator.goTo(path.path)}>
                                        {name.toUpperCase()}
                                    </Link>) :
                                    (<Typography key={key} sx={{ color: "white", textTransform: 'uppercase' }} >{name}</Typography>)
                            })
                        }
                    </Breadcrumbs>)
                }
            </Grid >
        );
    }

    return getContent();
}