import { ServiceItem } from "orderme-api-integration-client";
import { store } from "../redux/store";

const packingFee: number = 0;

export const originalCartWorth = () => {
    var servicesInBasket = getServicesInBasket();
    if (servicesInBasket.length === 0)
        return 0;

    var sum = servicesInBasket.map(a => a.originalPrice).reduce((a, b) => a + b);
    return roundPrice(sum);
}

export const cartWorth = () => {
    var servicesInBasket = getServicesInBasket();
    if (servicesInBasket.length === 0)
        return 0;

    var sum = servicesInBasket
        .map(a => getServicePrice(a, servicesInBasket.filter(b => b.id === a.id).length, !isLoggedIn()))
        .reduce((a, b) => a + b);
    return roundPrice(sum);
}

export const getFriendsCartWorth = () => {
    var servicesInBasket = getServicesInBasket();
    if (servicesInBasket.length === 0)
        return 0;

    var sum = servicesInBasket
        .map(a => getServicePrice(a, servicesInBasket.filter(b => b.id === a.id).length))
        .reduce((a, b) => a + b);
    return roundPrice(sum);
}

export const getServicePrice = (service: ServiceItem, amount: number, useOriginalPrice?: boolean) => {
    var price = useOriginalPrice ? service.originalPrice : service.price;
    if (amount <= 0)
        return price;

    if (service.prices?.length > 0) {
        var newPrice = service.prices.sort((a, b) => b.amount - a.amount).find(p => amount >= p.amount)?.price;
        if (newPrice < price)
            price = newPrice;
    }

    return price;
}

export const getFullPrice = (includeDelivery: boolean) => {
    var fullPrice = cartWorth();
    if (includeDelivery)
        fullPrice = getPriceWithDelivery(fullPrice);

    return fullPrice + packingFee;
}

export const getCreditsUsed = () => {
    var pricePaidWithCredits = getCartWorthPaidWithCredits();
    if (packingFee > 0)
        pricePaidWithCredits += packingFee;

    const credits = getUserCredits();

    return pricePaidWithCredits > credits ? credits : pricePaidWithCredits;
}

export const getFinalPrice = (includeDelivery: boolean) =>
    getFullPrice(includeDelivery) - getCreditsUsed()

export const getSavings = () => originalCartWorth() - cartWorth()

export const isFreeDelivery = () => {
    const deliveryService = getDeliveryService();
    const freeDeliveryFrom = getFreeDeliveryFrom();

    if (!deliveryService)
        return true;

    // If free delivery is not defined, all deliveries must be paid
    if (!freeDeliveryFrom)
        return false;

    return cartWorth() >= freeDeliveryFrom;
}

const getCartWorthPaidWithCredits = () => {
    var servicesInBasket = getServicesInBasket();
    if (servicesInBasket.length === 0)
        return 0;

    var sum = servicesInBasket
        .map(a => a.canBePaidWithCredits ?
            getServicePrice(a, servicesInBasket.filter(b => b.id === a.id).length, !isLoggedIn()) : 0)
        .reduce((a, b) => { return a + b; });
    return roundPrice(sum);
}

const getPriceWithDelivery = (fullPrice: number) => {
    const deliveryService = getDeliveryService();
    const freeDeliveryFrom = getFreeDeliveryFrom();
    if (deliveryService?.price > 0 && (!freeDeliveryFrom || fullPrice < freeDeliveryFrom))
        fullPrice += deliveryService.price;

    return fullPrice;
}

const getUserCredits = () => {
    const credit = store.getState().customerState.customerInfo.credit;
    return credit ? credit : 0;
}

const getServicesInBasket = () => store.getState().cartState.servicesInBasket;
const isLoggedIn = () => store.getState().authState.isLoggedIn;
const getDeliveryService = () => store.getState().pickupState.deliveryService;
const getFreeDeliveryFrom = () => store.getState().pickupState.freeDeliveryFrom;
const roundPrice = (num: number) => Math.round(num * 100) / 100;