import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./reducers/cartReducer"
import pickupReducer from "./reducers/pickupReducer"
import tabReducer from "./reducers/tabsReducer"
import appLoaderReducer from "./reducers/appLoaderReducer"
import customerReducer from "./reducers/customerReducer"
import menuReducer from "./reducers/menuReducer";
import authReducer from "./reducers/authReducer";
import linksReducer from "./reducers/linksReducer";

export const store = configureStore({
    reducer: {
        cartState: cartReducer,
        pickupState: pickupReducer,
        tabState: tabReducer,
        appLoaderState: appLoaderReducer,
        customerState: customerReducer,
        menuState: menuReducer,
        authState: authReducer,
        linksState: linksReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: false
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch