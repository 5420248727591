import { getLanguage } from "../helpers/localStorageService";

export class RouteConfig {
    path: {
        lt: string;
        en?: string;
        ru?: string;
    };
    title: string;
    element: JSX.Element;
    authType?: "guest" | "authenticated" | "onlyNotAuthenticated";
    approvedUsers?: boolean;

    constructor(
        path: { lt: string; en?: string; ru?: string },
        title: string,
        element: JSX.Element,
        authType?: "guest" | "authenticated" | "onlyNotAuthenticated",
        approvedUsers?: boolean) {
        this.path = path;
        this.title = title;
        this.element = element;
        this.authType = authType;
        this.approvedUsers = approvedUsers;
    }

    getAllPaths(): string[] {
        return Object.values(this.path).filter(Boolean) as string[];
    }

    getPathByLanguage(language: string): string {
        return this.path[language as keyof typeof this.path] || this.path.lt;
    }

    getPath(): string {
        const language = getLanguage();
        return this.path[language as keyof typeof this.path] || this.path.lt;
    }
}

export class PrivateRouteConfig extends RouteConfig {
    constructor(
        path: { lt: string; en?: string; ru?: string },
        title: string,
        element: JSX.Element,
        approvedUsers?: boolean
    ) {
        super(path, title, element, "authenticated", approvedUsers);
    }
}

