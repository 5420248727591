import { Box, Theme } from '@mui/material';
import { styled, SxProps } from '@mui/system';

export const ImageNavigationBox = styled(Box)({
    height: "100%",
    backgroundColor: "transparent",
    zIndex: 1,
    top: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center"
})

export const NavIconProps = ({
    height: "40px",
    color: "black",
    backgroundColor: "white",
    width: "40px",
    opacity: 0.5,
    borderRadius: "20px",
    '&:hover': {
        cursor: "pointer",
        opacity: 0.8
    }
}) as SxProps<Theme>;