import { useEffect, useState } from "react";
import { Grid, Box, TextField, CircularProgress, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { ErrorMessageBox, LinkStyledWebsite } from "./loginStyles"
import { ResetPassword } from "../resetPassword/resetPassword"
import { getStatusError } from "../../../helpers/errorMessage";
import { useTranslation } from 'react-i18next';
import { FacebookSignIn, GoogleSignIn } from "../../../controls";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { GoogleLoginError, LoginFieldValues, RegistrationProps } from "../../../interfaces/interfaces";
import LoadingButton from "@mui/lab/LoadingButton";
import { authenticate, authenticateFromApple, authenticateFromFacebook, authenticateFromGoogle, removeLoginError, setRememberMe } from "../../../redux/reducers/authReducer";
import { RootState } from "../../../redux/store";
import { CheckBox } from "../../checkBox/checkBox";
import * as externalLogin from "../../../helpers/externalLogin";
import { getUrlBeforeLogin, removeUrlBeforeLogin } from "../../../helpers/localStorageService";
import { AppleSignIn } from "../externalLogin/appleSignIn";
import { ExternalAuthenticationRequest } from "orderme-api-integration-client";
import { updateDocumentTitle } from "../../../helpers/documentHelper";
import { useRouteNavigator } from "../../../routes/useRouteNavigator";

const inputValues = {
  loginName: '',
  password: '',
}

export function Login() {
  const dispatch = useAppDispatch();
  const routeNavigator = useRouteNavigator();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const isAuthenticating = useAppSelector((state: RootState) => state.authState.loading);
  const authErrorMessage = useAppSelector((state: RootState) => state.authState.error);
  const rememberMe = useAppSelector((state: RootState) => state.authState.rememberMe)
  const [textFieldValues, setTextFieldValues] = useState<LoginFieldValues>(inputValues);
  const [showPswReset, setShowPswReset] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const urlBeforeLogin = getUrlBeforeLogin();
  const [googleUserGrantRequired, setGoogleUserGrantRequired] = useState(false);
  const [googleLoginHint, setGoogleLoginHint] = useState<string>(null);

  const { t } = useTranslation('account');

  updateDocumentTitle();

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    const fieldValue = { [name]: value } as Pick<LoginFieldValues, keyof LoginFieldValues>;

    setTextFieldValues({
      ...textFieldValues,
      ...fieldValue
    });
  }

  const login = () => {
    dispatch(authenticate({ input: textFieldValues.loginName, password: textFieldValues.password }));
  }

  useEffect(() => {
    dispatch(removeLoginError());
    removeUrlBeforeLogin();
    const client = query.get("client");
    if (client === "facebook") {
      handleFacebookSignIn(query.get('access_token'), query.get('state'));
    }
    else if (client === "google") {
      handleGoogleSignIn(query.get('access_token'), query.get('state'), query.get('code'));
    }
    else if (client === "apple") {
      handleAppleSignIn(query.get('access_token'), query.get('state'), query.get('code'), query.get('firstName'), query.get('lastName'));
    }

    externalLogin.removeAllStates();
  }, []);

  useEffect(() => {
    setErrorMessage(authErrorMessage);
  }, [authErrorMessage]);

  useEffect(() => {
    validate();
  }, [textFieldValues]);

  const validate = () => {
    if (Object.values(textFieldValues).every(value => value !== "")) {
      setIsButtonDisabled(false);
    }
    else {
      setIsButtonDisabled(true);
    }
  }

  const onLoginFailure = (err: any) => {
    var msg = getStatusError(err.status);
    setErrorMessage(msg);
  }

  const openResetPassword = () => {
    setShowPswReset(true);
  }

  const closeResetPassword = () => {
    setShowPswReset(false);
  }

  const handleFacebookSignIn = async (accessToken?: string, state?: string) => {
    console.debug(window.location.href);

    const signInState = externalLogin.getFacebookState();

    if (!accessToken || state !== signInState) {
      navigateToLogin();
      return;
    }

    try {
      await dispatch(authenticateFromFacebook({ accessToken: accessToken, state: state } as ExternalAuthenticationRequest)).unwrap();
      redirectAfterLogin();
    }
    catch (err) {
      console.log(err);
      const registrationProps = await externalLogin.getFacebookRegistrationProps(accessToken, state);
      navigateToRegistration(registrationProps);
    }
  };

  const handleGoogleSignIn = async (accessToken: string, state: string, code: string) => {
    console.debug(window.location.href);
    const googleState = externalLogin.getGoogleState();
    externalLogin.removeGoogleState();
    var googleToken = externalLogin.getGoogleTokenProps(accessToken);
    if (!accessToken || !code || state !== googleState.state || googleToken?.nonce !== googleState.nonce) {
      navigateToLogin();
      return;
    }

    try {
      const authRequest = { accessToken: accessToken, state: state, code: code } as ExternalAuthenticationRequest;
      await dispatch(authenticateFromGoogle(authRequest)).unwrap();
      redirectAfterLogin();
    }
    catch (err) {
      console.log(err);

      const loginError = err as GoogleLoginError;
      if (loginError?.userGrantRequired) {
        setGoogleLoginHint(googleToken.email);
        setGoogleUserGrantRequired(true);
        return;
      }
      const registrationProps = externalLogin.getGoogleRegistrationProps(accessToken, state, code);
      navigateToRegistration(registrationProps);
    }
  };

  const handleAppleSignIn = async (accessToken?: string, state?: string, code?: string, firstName?: string, lastName?: string) => {
    console.debug(window.location.href);
    const appleState = externalLogin.getAppleState();
    externalLogin.removeAppleState();

    var appleToken = externalLogin.getAppleTokenProps(accessToken);

    if (!accessToken || !code || state !== appleState?.state || appleToken?.nonce !== appleState.nonce) {
      navigateToLogin();
      return;
    }

    try {
      const authRequest = { accessToken: accessToken, state: state, code: code } as ExternalAuthenticationRequest;
      await dispatch(authenticateFromApple(authRequest)).unwrap();
      redirectAfterLogin();
    }
    catch (err) {
      console.log(err);
      const registrationProps = externalLogin.getAppleRegistrationProps(accessToken, state, code, firstName, lastName);
      navigateToRegistration(registrationProps);
    }
  };

  const navigateToRegistration = (registrationProps: RegistrationProps) => {
    routeNavigator.goToRegistration({ state: registrationProps, replace: true });
  }

  const redirectAfterLogin = () => {
    if (urlBeforeLogin)
      routeNavigator.goToPath(urlBeforeLogin, { replace: true });
    else
      routeNavigator.goHome(true);
  }

  const navigateToLogin = () => {
    routeNavigator.goToLogin({ replace: true });
  }

  return (
    <Box>
      <Box>
        <Grid sx={{ mt: "20px", gap: "10px" }} container justifyContent="center" alignItems="center" >
          <TextField
            InputProps={{
              disableUnderline: true,
              sx: {
                borderRadius: "20px"
              }
            }}
            name="loginName"
            onChange={handleFieldChange}
            label={t("loginInput")}
            variant="filled" />
          <TextField
            InputProps={{
              disableUnderline: true,
              sx: {
                borderRadius: "20px"
              }
            }}
            name="password"
            onChange={handleFieldChange}
            label={t("password")}
            type="password"
            variant="filled" />
        </Grid>
        <Grid container justifyContent="center" alignItems="center" direction="column">
          {errorMessage && <ErrorMessageBox>{errorMessage}</ErrorMessageBox>}
          <Grid sx={{ width: "220px", mt: "10px" }} container>
            <CheckBox isChecked={rememberMe} backgroundColor="white" onChange={() => dispatch(setRememberMe(!rememberMe))} />
            <Typography sx={{ ml: "20px" }} onClick={() => dispatch(setRememberMe(!rememberMe))}>{t('rememberMe')}
            </Typography>
          </Grid>
          <LoadingButton
            sx={{ width: "220px", mt: "10px", mb: "5px", }}
            onClick={() => login()}
            disabled={isButtonDisabled}
            loading={isAuthenticating}
            loadingIndicator={<CircularProgress sx={{ color: "paulini.blue" }} />}
            variant="contained"
          >
            <Box sx={{ color: "white" }}>{t('login')}</Box>
          </LoadingButton>
          <GoogleSignIn
            isVisible={!isAuthenticating}
            onError={err => onLoginFailure(err)}
            userGrantRequired={googleUserGrantRequired}
            loginHint={googleLoginHint} />
          <FacebookSignIn isVisible={!isAuthenticating} onError={err => onLoginFailure(err)} />
          <AppleSignIn isVisible={!isAuthenticating} onError={err => onLoginFailure(err)} />
          <LinkStyledWebsite underline="hover" onClick={() => openResetPassword()}>{t('forgotPassword')}</LinkStyledWebsite>
          <LinkStyledWebsite underline="hover" onClick={() => routeNavigator.goToRegistration()}>{t('registration')}</LinkStyledWebsite>
          <LinkStyledWebsite underline="hover" onClick={() => routeNavigator.goToSupport()}>{t('problemsToLogin')}</LinkStyledWebsite>
        </Grid>
      </Box>
      <ResetPassword open={showPswReset} onClose={closeResetPassword} />
    </Box >
  );
}