import { Grid, Box } from "@mui/material";
import { CartInfo } from "./cartInfo";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { StickyCartBar } from "../appBars/cartBar/stickyCartBar";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useEffect } from "react";
import { NavBar } from "../appBars/navBar/navBar";
import { useTranslation } from "react-i18next";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { useRouteNavigator } from "../../routes/useRouteNavigator";
import { CartRoute, OrderConfirmationRoute } from "../../routes/allRoutes";

export function CartSummary() {
    const routeNavigator = useRouteNavigator();
    const deliveryType = useAppSelector((state: RootState) => state.pickupState.deliveryType);
    const { t } = useTranslation('payment');

    useEffect(() => {
        if (!deliveryType)
            routeNavigator.goToCart({ replace: true });
    }, []);

    const buy = () => {
        routeNavigator.goToPaymentMethods();
    }

    return (
        <Grid container direction="column" alignItems="center">
            <NavBar barTitle={t("orderConfirmation")} />
            <BreadcrumbsBar paths={[{ path: CartRoute, isLink: true }, { path: OrderConfirmationRoute }]} />
            <Box sx={{ maxWidth: "1000px", width: "100%", backgroundColor: "white", borderRadius: "15px" }}>
                <Box sx={{ m: "15px" }} className={appStyleMode === AppStyle.Mobile && "cart-summary"}>
                    <CartInfo includeDelivery={true} />
                    {
                        appStyleMode === AppStyle.Mobile ?
                            (<BottomCartBar open={buy} isConfirmation={true} isPurchase={true} />) :
                            <StickyCartBar open={buy} isConfirmation={true} isPurchase={true} />
                    }
                </Box>
            </Box>
        </Grid>
    );
}