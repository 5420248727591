import { Box, Grid, IconButton, ImageListItem, Link, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { ServiceItem } from "orderme-api-integration-client";
import { Linear } from "../progressBars/linear";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { HtmlViewer } from "../../controls/html/htmlViewer";
import { ServiceClient } from "../../helpers/client";
import { useParams } from "react-router";
import { useRouteNavigator } from "../../routes/useRouteNavigator";
import { GoodsItemRoute, GoodsRoute } from "../../routes/allRoutes";
import useGetParameter from "../../hooks/useGetParameter";
import { RouterParam } from "../../helpers/routerParam";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { generateSlug } from "../../helpers/slugGenerator";
import { ImageLoader } from "../imgLoader/imgLoader";
import { apiKey, appStyleMode, imagesUri } from "../../helpers/clientConfigs";
import { useTranslation } from "react-i18next";
import { ImagesCarousel } from "../carousel/imagesCarousel";
import { ImageNavPrev } from "../carousel/imageNavPrev";
import { ImageNavNext } from "../carousel/imageNavNext";
import { useSwipeable } from "react-swipeable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { StickyProductCartBox } from "./stickyProductCartBox";
import { AppStyle } from "../../helpers/appStyle";
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export function GoodsItem() {
    const routeNavigator = useRouteNavigator();
    const theme = useTheme();
    const { t } = useTranslation();
    const serviceClient = new ServiceClient();
    const params = useParams<"name">();
    const idParam = useGetParameter(RouterParam.Id);
    const [serviceItemId, setServiceItemId] = useState<string>();
    const [service, setService] = useState<ServiceItem>();
    const [currentImageId, setCurrentImageId] = useState<string>();
    const [serviceImages, setServiceImages] = useState<string[]>();
    const [imageIndex, setImageIndex] = useState(0);
    const [isHoveredImages, setIsHoveredImages] = useState(false);
    const [description, setDescription] = useState<string>();
    const [isDescriptionExpandable, setIsDescriptionExpandable] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const links = useAppSelector((state: RootState) => state.linksState.physicalItems);
    const isUpSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const isDownMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const visibleImagesCount = (isUpSmallScreen && isDownMediumScreen) || isLargeScreen ? 5 : 3;

    useEffect(() => {
        const link = links.find(link => link.id === idParam);
        if (link)
            setServiceItemId(link.dataId);
        else
            routeNavigator.goHome();
    }, [idParam])

    useEffect(() => {
        if (serviceItemId)
            getServiceItem(serviceItemId);
        else
            setService(null);

    }, [serviceItemId])

    useEffect(() => {
        if (service) {
            if (params.name !== generateSlug(service.name))
                routeNavigator.goToGoodsItem(service);

            if (service.images && service.images.length > 0) {
                setServiceImages([service.logoId, ...service.images.map(image => image.imageId)]);
            } else {
                setServiceImages([service.logoId]);
            }

            if (service.description) {
                collapseDescription();
            }
            else {
                setDescription(null);
                setIsDescriptionExpandable(false);
                setIsExpanded(false);
            }
        }
    }, [service])

    useEffect(() => {
        if (serviceImages)
            setCurrentImageId(serviceImages[imageIndex]);
        else
            setCurrentImageId(null);

    }, [imageIndex, serviceImages])

    const getServiceItem = async (id: string) => {
        try {
            setService(await serviceClient.getServiceById(id));
        } catch (error) {
            console.error(`Error fetching service item by ID=${id}:`, error);
            setService(null);
            routeNavigator.goHome();
        }
    };

    const showNextImage = () => {
        if (imageIndex < serviceImages.length - 1)
            setImageIndex(imageIndex + 1);
        else
            setImageIndex(0);
    }

    const showPreviousImage = () => {
        if (imageIndex > 0)
            setImageIndex(imageIndex - 1);
        else
            setImageIndex(serviceImages.length - 1);
    }

    const imageSwipeHandler = useSwipeable({
        onSwipedLeft: () => showNextImage(),
        onSwipedRight: () => showPreviousImage(),
    });

    const expandDescription = () => {
        setDescription(service.description);
        setIsExpanded(true);
    }

    const collapseDescription = () => {
        const endOfParagraph = service.description.indexOf("</p>");
        const shortDescription = endOfParagraph > 0 ?
            service.description.substring(0, endOfParagraph + 4) : service.description;
        setDescription(shortDescription);
        setIsDescriptionExpandable(shortDescription !== service.description);
        setIsExpanded(false);
    }

    const appImageContainerProps = ({
        height: "250px",
        p: "0px"
    }) as SxProps<Theme>;

    const webImageContainerProps = ({
        height: { xs: "300px", sm: "400px" },
        pl: "25px",
        pr: { xs: "25px", md: "15px" },
        pt: "5px",
        mx: "auto"
    }) as SxProps<Theme>;

    return (
        <Box sx={{ mt: appStyleMode === AppStyle.Mobile ? "0px" : "10px" }}>
            {!service && (<Linear />)}
            <BreadcrumbsBar paths={[{ path: GoodsRoute, isLink: true }, { path: GoodsItemRoute, title: service?.name }]} />
            {service &&
                <Grid sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    color: "black",
                    pt: appStyleMode === AppStyle.Mobile ? "0px" : "20px",
                    pb: "15px"
                }}>
                    {appStyleMode === AppStyle.Mobile &&
                        <IconButton sx={{
                            position: 'absolute',
                            zIndex: 2,
                            color: "black",
                            backgroundColor: "white",
                            opacity: 0.75,
                            ml: "5px",
                            mt: "5px"
                        }} onClick={() => routeNavigator.goBack()}>
                            <ArrowBackIcon />
                        </IconButton>
                    }
                    <Grid container direction="row" justifyContent="space-between" className={appStyleMode === AppStyle.Mobile && "goods-item"}>
                        {currentImageId &&
                            <Grid item xs={12} md={6} sx={{ maxWidth: "500px" }}>
                                <Grid container direction="column" justifyContent="space-between"
                                    sx={{ height: "100%", textAlign: "center" }}>
                                    <Box sx={{ zIndex: 1, backgroundColor: "white" }}>
                                        <Box
                                            sx={
                                                appStyleMode === AppStyle.Mobile ?
                                                    appImageContainerProps : webImageContainerProps}
                                            onMouseEnter={() => setIsHoveredImages(true)}
                                            onMouseLeave={() => setIsHoveredImages(false)}
                                            {...imageSwipeHandler}>
                                            <ImageListItem sx={{
                                                width: "100%",
                                                height: "100% !important",
                                            }}>
                                                <ImageLoader sx={{ display: "block", position: "absolute", objectFit: "cover", height: "100%", width: "100%" }}
                                                    src={`${imagesUri}/${currentImageId}?orderme-api-key=${apiKey}`}
                                                    alt={service.name} />
                                                {serviceImages?.length > 1 && (
                                                    <>
                                                        <ImageNavPrev visible={isHoveredImages} onClick={showPreviousImage} />
                                                        <ImageNavNext visible={isHoveredImages} onClick={showNextImage} />
                                                    </>
                                                )}
                                            </ImageListItem>
                                        </Box>
                                        {serviceImages?.length > 1 &&
                                            <ImagesCarousel
                                                selectedItem={imageIndex}
                                                images={serviceImages}
                                                visibleItems={visibleImagesCount}
                                                itemWidth={100}
                                                onImageClick={(index) => setImageIndex(index)} />
                                        }
                                    </Box>
                                    <StickyProductCartBox service={service} visible={!isDownMediumScreen} />
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs sx={{ textAlign: "center", pl: { xs: "5px", md: "0px" }, pr: "5px" }}>
                            <Typography sx={{ fontSize: "28px", px: "15px" }}>
                                {service.name}
                            </Typography>
                            <Box>
                                <HtmlViewer content={description} />
                            </Box>
                            {isDescriptionExpandable &&
                                <Box sx={{ textAlign: "left", px: "15px", pb: "10px", mt: "-15px" }}>
                                    <Link component="button"
                                        underline="hover"
                                        color="paulini.blue"
                                        fontWeight="bold"
                                        sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}
                                        onClick={() => isExpanded ? collapseDescription() : expandDescription()}>
                                        {isExpanded ?
                                            <>
                                                {t('services:lessDetails')}
                                                <ExpandLessIcon sx={{ color: "paulini.blue" }} />
                                            </> :
                                            <>
                                                {t('services:moreDetails')}
                                                <ExpandMoreIcon sx={{ color: "paulini.blue" }} />
                                            </>
                                        }
                                    </Link>
                                </Box>
                            }
                            <StickyProductCartBox service={service} visible={isDownMediumScreen} />
                        </Grid>
                    </Grid>
                    {appStyleMode === AppStyle.Mobile && <BottomCartBar />}
                </Grid>
            }
        </Box>
    );
}