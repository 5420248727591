import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { NavBar } from "../appBars/navBar/navBar";
import StoreIcon from "../../logo/store.png"
import HomeIcon from "../../logo/home.png"
import ClosetIcon from "../../logo/closet.png"
import BusinessLockerIcon from "../../logo/businessLocker.png"
import { WhereToItem } from "./whereToItem"
import { DeliveryClient, ServiceClient, SettingClient } from "../../helpers/client";
import { DeliveryOptions, DeliveryType, ServiceItem } from "orderme-api-integration-client";
import { ImageLoader } from "../imgLoader/imgLoader";
import { useTranslation } from 'react-i18next';
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { clearDelivery, updateDeliveryService, updateFreeDeliveryFrom } from "../../redux/reducers/pickupReducer";
import { useAppDispatch } from "../../redux/hooks";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { useRouteNavigator } from "../../routes/useRouteNavigator";
import { CartRoute, WhereToRoute } from "../../routes/allRoutes";
import { onlyPhysicalItemsInBasket } from "../../helpers/cartVerifier";
import { cartWorth } from "../../helpers/costCounter";

export function WhereTo() {
  const routeNavigator = useRouteNavigator();
  const dispatch = useAppDispatch();
  const deliveryClient: DeliveryClient = new DeliveryClient();
  const settingClient: SettingClient = new SettingClient();
  const serviceClient: ServiceClient = new ServiceClient();
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptions[]>();
  const [deliveryServiceId, setDeliveryServiceId] = useState<string>();
  const [freeDeliveryFrom, setFreeDeliveryFrom] = useState<number>(0);
  const [deliveryService, setDeliveryService] = useState<ServiceItem>();
  const [deliveryPrice, setDeliveryPrice] = useState<number>(0);
  const [parcelLockerDeliveryServiceId, setParcelLockerDeliveryServiceId] = useState<string>();
  const [parcelLockerFreeDeliveryFrom, setParcelLockerFreeDeliveryFrom] = useState<number>(0);
  const [parcelLockerDeliveryService, setParcelLockerDeliveryService] = useState<ServiceItem>();
  const [parcelLockerDeliveryPrice, setParcelLockerDeliveryPrice] = useState<number>(0);
  const { t } = useTranslation();
  const priceUpToFreeDelivery = freeDeliveryFrom > 0 ? freeDeliveryFrom - cartWorth() : 0;
  const parcelLockerPriceUpToFreeDelivery = parcelLockerFreeDeliveryFrom > 0 ? parcelLockerFreeDeliveryFrom - cartWorth() : 0;

  useEffect(() => {
    getDeliverySettings();
    getDeliveryOptions();
    dispatch(clearDelivery());
  }, []);

  useEffect(() => {
    if (!deliveryServiceId)
      return;

    serviceClient.getServiceById(deliveryServiceId).then((response) => {
      setDeliveryService(response);
      setDeliveryPrice(response.price);
    }).catch((err) => console.log(err));
  }, [deliveryServiceId]);

  useEffect(() => {
    if (!parcelLockerDeliveryServiceId)
      return;

    serviceClient.getServiceById(parcelLockerDeliveryServiceId).then((response) => {
      setParcelLockerDeliveryService(response);
      setParcelLockerDeliveryPrice(response.price);
    }).catch((err) => console.log(err));
  }, [parcelLockerDeliveryServiceId]);

  const getDeliveryOptions = () => {
    deliveryClient.get().then((response) => {
      setDeliveryOptions(response);
      console.log(response);
    }).catch((err) => console.log(err));
  }

  const getDeliverySettings = () => {
    settingClient.get().then((response) => {
      setFreeDeliveryFrom(response.orders.freeDeliverFrom);
      setDeliveryServiceId(response.orders.deliveryServiceId);
      setParcelLockerFreeDeliveryFrom(response.orders.parcelLockerFreeDeliverFrom);
      setParcelLockerDeliveryServiceId(response.orders.parcelLockerDeliveryServiceId);
    }).catch((err) => console.log(err));
  }

  const openPage = (type: DeliveryType) => {
    switch (type) {
      case DeliveryType.SalesPoint:
        routeNavigator.goToSalesPointDelivery();
        break;
      case DeliveryType.Wardrobe:
        routeNavigator.goToBusinessLockerDelivery();
        break;
      case DeliveryType.Address:
        dispatch(updateDeliveryService(deliveryService));
        dispatch(updateFreeDeliveryFrom(freeDeliveryFrom));
        routeNavigator.goToAddressDelivery();
        break;
      case DeliveryType.ForeignLocker:
        dispatch(updateDeliveryService(parcelLockerDeliveryService));
        dispatch(updateFreeDeliveryFrom(parcelLockerFreeDeliveryFrom));
        routeNavigator.goToParcelLockerProviders();
        break;
    }
  }

  const itemImage = (type: DeliveryType) => {
    switch (type) {
      case DeliveryType.SalesPoint:
        return StoreIcon;
      case DeliveryType.Wardrobe:
        return BusinessLockerIcon;
      case DeliveryType.Address:
        return HomeIcon;
      case DeliveryType.ForeignLocker:
        return ClosetIcon;
    }
  }

  const isEnabled = (type: DeliveryType) => {
    if (type === DeliveryType.ForeignLocker) {
      if (!onlyPhysicalItemsInBasket())
        return false;

      return parcelLockerPriceUpToFreeDelivery <= 0 || parcelLockerDeliveryPrice > 0;
    }

    if (type === DeliveryType.Address) {
      return priceUpToFreeDelivery <= 0 || deliveryPrice > 0;
    }

    return true;
  }

  const getDescription = (type: DeliveryType) => {
    switch (type) {
      case DeliveryType.Address:
        if (priceUpToFreeDelivery > 0) {
          return [
            t('payment:freeDeliveryFrom', { price: freeDeliveryFrom.toPrice() }),
            t('payment:priceUpToFreeDelivery', { price: priceUpToFreeDelivery.toPrice() })
          ];
        }
        return;

      case DeliveryType.ForeignLocker:
        if (!onlyPhysicalItemsInBasket())
          return [t('payment:deliveryToParcelLockerForGoods')];

        if (parcelLockerPriceUpToFreeDelivery > 0) {
          return [
            t('payment:parcelLockerFreeDeliveryFrom', { price: parcelLockerFreeDeliveryFrom.toPrice() }),
            t('payment:priceUpToFreeDelivery', { price: parcelLockerPriceUpToFreeDelivery.toPrice() })
          ];
        }

        return;

      default:
        return [];
    }
  }

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('payment:whereToDeliver')} />
      <BreadcrumbsBar paths={[{ path: CartRoute, isLink: true }, { path: WhereToRoute }]} />
      <Grid
        sx={{ maxWidth: "1000px" }}
        container
        direction="column"
        justifyContent="center"
        alignItems="center">
        <Box sx={{ width: appStyleMode !== AppStyle.Mobile ? "90%" : null }} className={appStyleMode === AppStyle.Mobile && "where-to-list"}>
          {deliveryOptions?.map((option) => (
            <WhereToItem
              title={option.name}
              openPage={() => openPage(option.type)}
              disabled={!isEnabled(option.type)}
              description={getDescription(option.type)}
              icon={<ImageLoader src={itemImage(option.type)} sx={{ height: "100px" }} alt={option.name} />} />
          ))}
        </Box>
      </Grid>
    </Grid >
  );
}
