import { useState } from "react";
import { Typography, Container } from "@mui/material";
import { Popup } from "../../components/popup/popup";
import { CustomerClient } from "../../helpers/client"
import PhoneConfirmIcon from "../../logo/phoneconfirmation.png"
import { ConfirmPhoneButton, InputStyled, PhoneLogoImg } from "./styles"
import { useTranslation } from 'react-i18next';
import { forceLoginAgain } from "../../redux/reducers/authReducer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { confirmPhone, waitPhoneConfirmation } from "../../redux/reducers/customerReducer";
import { useRouteNavigator } from "../../routes/useRouteNavigator";

const ConfirmPhone = ({ isOpened }: { isOpened: boolean }) => {
    const routeNavigator = useRouteNavigator();
    const dispatch = useAppDispatch();
    const isCodeSent = useAppSelector((state: RootState) => state.customerState.isPhoneConfirmationSent);
    const [isBusy, setIsBusy] = useState(false);
    const [code, setCode] = useState('');
    const customersClient: CustomerClient = new CustomerClient();
    const { t } = useTranslation('account');

    const sendCode = async () => {
        dispatch(waitPhoneConfirmation());
        try {
            setIsBusy(true);
            await customersClient.sendPhoneConfirmation();
        }
        catch (error) {
            console.error("Failed to send confirmation sms")
        }
        finally {
            setIsBusy(false);
        }
    };

    const confirmCode = async () => {
        try {
            setIsBusy(true);

            await customersClient.confirmPhone(code);
            dispatch(confirmPhone());
            dispatch(forceLoginAgain());
        }
        catch (error) {
            console.error("Failed to confirm phone")
        }
        finally {
            setIsBusy(false);
        }
    };

    return (
        <Popup open={isOpened} onClose={() => routeNavigator.goBack()} dialogTitle={t('confirmDialogTitle')}
            dialogContent={
                <>
                    <Container sx={{ margin: "20px 0", display: "flex" }}>
                        <Container sx={{ padding: "0px" }}>
                            <Typography sx={{ fontSize: "20px" }}>
                                {t('confirmPhoneToContinue')}
                            </Typography>
                            <InputStyled type="number" onChange={(e) => setCode(e.target.value)} placeholder={t('phoneConfirmationExample')} />
                        </Container>
                        <PhoneLogoImg src={PhoneConfirmIcon} alt="Phone logo" />
                    </Container>
                </>
            }

            dialogActions={
                <ConfirmPhoneButton disabled={isBusy || (isCodeSent && code.length !== 6)} onClick={() => isCodeSent ? confirmCode() : sendCode()}>
                    <Typography sx={{ color: "white" }}>
                        {isCodeSent ? t('confirmPhoneOnPopup') : t('getPhoneConfirmationCode')}
                    </Typography>
                </ConfirmPhoneButton>
            } />
    );
}

export { ConfirmPhone };