import { Box, Grid } from "@mui/material";
import { Login } from "../auth/login/login";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { ServiceCarousel } from "../carousel/serviceCarousel";
import { useTranslation } from "react-i18next";
import { RouterParam } from "../../helpers/routerParam";
import useGetParameter from "../../hooks/useGetParameter";
import { Navigate } from "react-router";
import { updateDocumentTitle } from "../../helpers/documentHelper";
import { Banner } from "../banner/banner";
import { DiscountBanner } from "../banner/discountBanner";
import detergentsBannerLogo from "../../logo/detergentsBanner.png"
import detergentsBannerLogoSmall from "../../logo/detergentsBannerSmall.png"
import { useRouteNavigator } from "../../routes/useRouteNavigator";

export function WebsiteHomePage() {
    const isUserLoggedIn: boolean = useAppSelector((state: RootState) => state.authState.isLoggedIn);
    const routeNavigator = useRouteNavigator();
    const { t } = useTranslation();
    const pageId = useGetParameter(RouterParam.PageId);
    updateDocumentTitle();

    const isOldRulesPage = () => {
        return pageId === "11";
    }

    return (
        isOldRulesPage() ?
            (<Navigate to={routeNavigator.getRulesPath()} replace />) :
            (<Grid sx={{ mt: "10px" }}>
                <ServiceCarousel />
                <Banner image={detergentsBannerLogo} smallImage={detergentsBannerLogoSmall} url={routeNavigator.getGoodsPath()} />
                <DiscountBanner />
                <Box sx={{
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    color: "white",
                    fontSize: "26px"
                }}>
                    {t("info:homePageTitle")}
                </Box>
                {!isUserLoggedIn && (<Login />)}

            </Grid>)
    );
}