import { env } from "../../env";
declare let window: any;

export const openRules = (rulesPath: string) => {
  console.debug(rulesPath);
  if (window.cordova) {
    window.cordova.InAppBrowser.open("https://paulini.lt" + rulesPath, "_system", "location=yes");
  }
  else {
    if (env.PUBLIC_URL)
      window.open(env.PUBLIC_URL + rulesPath, "_blank");
    else
      window.open(rulesPath, "_blank");
  }
}