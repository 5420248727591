import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { useTranslation } from "react-i18next";
import { CartBar } from "./cartBar";

export interface StickyCartBarProps {
    open?: () => void;
    /** True if purchase is already started */
    isPurchase?: boolean;
    /** True if it's final step before payment. Default value is false. */
    isConfirmation?: boolean;
    isDisabled?: boolean;
    sx?: SxProps<Theme>;
}

export function StickyCartBar(props: StickyCartBarProps) {
    const { t } = useTranslation('payment');
    const {
        isPurchase = false,
        isConfirmation = false,
    } = props;

    return (<CartBar
        position="sticky"
        barText={isConfirmation ? t('pay') : isPurchase ? t('continue') : t('cart')}
        isPurchase={props.isPurchase}
        isDisabled={props.isDisabled}
        includeDelivery={isConfirmation}
        open={props.open}
        sx={[
            {
                width: "auto",
                borderRadius: "10px"
            },
            ...(Array.isArray(props.sx) ? props.sx : [props.sx])]} />)
}