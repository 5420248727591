import { useNavigate, NavigateOptions } from "react-router";
import { AppStyle } from "../helpers/appStyle";
import { appStyleMode } from "../helpers/clientConfigs";
import * as Routes from "./allRoutes";
import { RouteConfig } from "./routeConfig";
import { ServiceCategory, ServiceGroup, ServiceItem, ShortDataLinkType } from "orderme-api-integration-client";
import { generateSlug } from "../helpers/slugGenerator";
import { store } from "../redux/store";
import { isGoodsCategory } from "../helpers/servicesHelper";

export const useRouteNavigator = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);
    const goTo = (to: RouteConfig, options?: NavigateOptions) => navigateTo(to, options);
    const goToPath = (path: string, options?: NavigateOptions) => navigate(path, options);

    const goHome = (replace?: boolean, searchParams?: string) => {
        const options = getNavigationOptions(replace);
        navigateTo(getHomeRoute(), options, searchParams);
    };

    const getHomePath = () => getRoutePath(getHomeRoute(), null, null);

    const getHomeRoute = () => appStyleMode === AppStyle.Mobile ? Routes.MobileHomeRoute : Routes.HomeRoute;

    const goToSearch = () => navigateTo(Routes.SearchRoute);
    const goToCart = (options?: NavigateOptions) => navigateTo(Routes.CartRoute, options);
    const goToAccount = () => navigateTo(Routes.AccountRoute);
    const goToRegistration = (options?: NavigateOptions) => navigateTo(Routes.RegistrationRoute, options);
    const goToAchievements = () => navigateTo(Routes.AchievementsRoute);
    const goToRules = () => navigateTo(Routes.RulesRoute);
    const getRulesPath = () => getRoutePath(Routes.RulesRoute, null, null);
    const goToPrivacyPolicy = () => navigateTo(Routes.PrivacyPolicyRoute);
    const goToSalesPoints = () => navigateTo(Routes.SalesPointsRoute);
    const goToSalesPoint = (options?: NavigateOptions) => navigateTo(Routes.SalesPointRoute, options);
    const goToSalesPointDelivery = () => navigateTo(Routes.SalesPointDeliveryRoute, { state: true });
    const goToBusinessLockerDelivery = () => navigateTo(Routes.BusinessLockerDeliveryRoute, { state: true });
    const goToParcelLockerProviders = () => navigateTo(Routes.ParcelLockerProviderRoute);
    const goToAddressDelivery = () => navigateTo(Routes.AddressDeliveryRoute, { state: true });
    const goToPartners = () => navigateTo(Routes.PartnersRoute);
    const goToNews = () => navigateTo(Routes.NewsRoute);
    const goToNewsItem = (id: string, title: string) => {
        const links = store.getState().linksState.news;
        const link = links.find(link => link.dataId === id);
        if (link) {
            title = generateSlug(title);
            navigateTo(Routes.NewsItemRoute, null, `id=${link.id}`, { title });
        }
    }
    const goToAboutUs = () => navigateTo(Routes.AboutUsRoute);
    const goToWhereTo = () => navigateTo(Routes.WhereToRoute);
    const goToAddressBook = (options?: NavigateOptions) => navigateTo(Routes.AddressBookRoute, options);
    const goToAccountSettings = (options?: NavigateOptions) => navigateTo(Routes.AccountSettingsRoute, options);
    const goToCredits = () => navigateTo(Routes.CreditsRoute);
    const goToPaymentMethods = (options?: NavigateOptions) => navigateTo(Routes.PaymentMethodsRoute, options);
    const goToOrderConfirmation = (options?: NavigateOptions) => navigateTo(Routes.OrderConfirmationRoute, options);
    const goToOrderHistory = (page?: number, replace?: boolean) => {
        const options = getNavigationOptions(replace);
        var searchParams: string;
        if (page) {
            searchParams = `page=${page}`;
        }

        navigateTo(Routes.OrderHistoryRoute, options, searchParams);
    }
    const goToOrderDetail = (id: string) => navigateTo(Routes.OrderDetailRoute, null, `id=${id}`);
    const goToLogin = (options?: NavigateOptions) => navigateTo(Routes.LoginRoute, options);
    const goToSupport = (options?: NavigateOptions) => navigateTo(Routes.SupportRoute, options);

    const goToCategory = (category: ServiceCategory, options?: NavigateOptions) => {
        const path = getCategoryPath(category);
        navigate(path, options);
    }

    const getCategoryPath = (category: ServiceCategory) => {
        if (isGoodsCategory(category))
            return getRoutePath(Routes.GoodsRoute, null, null);

        const links = store.getState().linksState.categories;
        const link = links.find(link => link.dataId === category.id);
        const name = link ? generateSlug(category.name) : "";
        return getRoutePath(Routes.CategoryRoute, null, { category: name, categoryId: link.id });
    }
    const goToCategoryGroup = (
        category: ServiceCategory,
        group: ServiceGroup,
        replace?: boolean) => {
        const groupLinks = store.getState().linksState.groups;
        const categoryLinks = store.getState().linksState.categories;
        const categoryLink = categoryLinks.find(link => link.dataId === category.id);
        const groupLink = groupLinks.find(link => link.dataId === group.id);
        const categoryName = generateSlug(category.name);
        const groupName = generateSlug(group.name);
        if (categoryLink && groupLink) {
            const options = getNavigationOptions(replace);
            navigateTo(
                Routes.CategoryGroupRoute,
                options,
                `group=${groupLink.id}`,
                { category: categoryName, group: groupName, categoryId: categoryLink.id });
        }
    }
    const goToInfo = () => navigateTo(Routes.InfoRoute);

    const goToGoods = () => navigateTo(Routes.GoodsRoute);

    const goToGoodsItem = (service: ServiceItem) => {
        const links = store.getState().linksState.physicalItems;
        const link = links.find(link => link.dataId === service.id)
        if (link) {
            const name = generateSlug(service.name);
            navigateTo(Routes.GoodsItemRoute, null, `id=${link.id}`, { name });
        }
    }

    const getGoodsPath = () => getRoutePath(Routes.GoodsRoute, null, null);

    const getServicesPath = () => getRoutePath(Routes.ServicesRoute, null, null);

    const navigateTo = (
        to: RouteConfig,
        options?: NavigateOptions,
        searchParams?: string,
        navigationParams?: { [key: string]: string }) => {
        var path = getRoutePath(to, searchParams, navigationParams);
        if (!options)
            options = getNavigationOptions();

        navigate(path, options);
    };

    const getRoutePath = (to: RouteConfig, searchParams: string, navigationParams: { [key: string]: string; }) => {
        var path = appStyleMode === AppStyle.Mobile ?
            to.path.lt : to.getPath();

        if (searchParams)
            path += `?${searchParams}`;

        if (navigationParams) {
            Object.keys(navigationParams).forEach(key => {
                path = path.replace(`:${key}`, navigationParams[key]);
            });
        }
        return path;
    }

    const getNavigationOptions = (replace?: boolean) => {
        const options: NavigateOptions = {};
        if (replace)
            options.replace = true;

        return options;
    }

    return {
        goBack,
        goHome,
        getHomePath,
        goTo,
        goToPath,
        goToSearch,
        goToCart,
        goToAccount,
        goToRegistration,
        goToAchievements,
        goToRules,
        getRulesPath,
        goToPrivacyPolicy,
        goToSalesPoints,
        goToSalesPoint,
        goToSalesPointDelivery,
        goToBusinessLockerDelivery,
        goToParcelLockerProviders,
        goToAddressDelivery,
        goToPartners,
        goToNewsItem,
        goToNews,
        goToAboutUs,
        goToWhereTo,
        goToAddressBook,
        goToAccountSettings,
        goToCredits,
        goToPaymentMethods,
        goToOrderConfirmation,
        goToOrderHistory,
        goToOrderDetail,
        goToLogin,
        goToSupport,
        goToCategory,
        goToCategoryGroup,
        goToInfo,
        getCategoryPath,
        goToGoods,
        goToGoodsItem,
        getGoodsPath,
        getServicesPath
    };
};

