import { BoxStyled } from "./creditsStyles"
import { Box, Grid, Typography } from "@mui/material";
import { NavBar } from "../appBars/navBar/navBar";
import { CustomerInfo } from "orderme-api-integration-client";
import { RootState } from "../../redux/store";
import { ActivateCoupon } from "./activateCoupon";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../redux/hooks";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { BuyCredits } from "./buyCredits";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { CreditsRoute } from "../../routes/allRoutes";

export function Credits() {
    const { t } = useTranslation();

    const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo)
    const isUserLoggedIn: boolean = useAppSelector((state: RootState) => state.authState.isLoggedIn);

    return (
        <Grid container direction="column" alignItems="center">
            <NavBar barTitle={t('credits:creditsAndCoupons').toUpperCase()} />
            <BreadcrumbsBar paths={[{ path: CreditsRoute }]} />
            <Box sx={{ width: "100%", maxWidth: "1000px", pb: "20px", backgroundColor: "white", borderRadius: "15px" }}>
                <Box className={appStyleMode === AppStyle.Mobile && "credits-box"}>
                    {isUserLoggedIn &&
                        <>
                            <BoxStyled>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center">

                                    <Typography variant="body1" gutterBottom component="div">
                                        {t('credits:totalCredits')}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom component="div">
                                        {customerInfo.credit?.toPrice()}
                                    </Typography>
                                </Grid>
                            </BoxStyled>
                            <Typography sx={{ color: "paulini.error", fontSize: "16px", mx: "25px" }}>{t('payment:creditsCanBeUsedFor')} {t('payment:creditsNowALlowed')}</Typography>
                        </>
                    }
                    {isUserLoggedIn && <ActivateCoupon />}
                    <BuyCredits />
                </Box>
            </Box>
        </Grid>
    )
}