import { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from "@mui/material";
import { ServiceItem } from 'orderme-api-integration-client';
import { apiKey, appStyleMode, imagesUri } from '../../helpers/clientConfigs';
import { ImageLoader } from '../imgLoader/imgLoader';
import { BreadcrumbsBar } from '../breadcrumbs/breadcrumbsBar';
import { useTranslation } from 'react-i18next';
import { GoodsRoute } from '../../routes/allRoutes';
import { useRouteNavigator } from '../../routes/useRouteNavigator';
import { ServiceClient } from '../../helpers/client';
import { roundDoubleNumbers } from '../../helpers/currency';
import { addToCart } from '../../redux/reducers/cartReducer';
import { useAppDispatch } from '../../redux/hooks';
import { AppStyle } from '../../helpers/appStyle';

export function GoodsList() {
    const [goods, setGoods] = useState<ServiceItem[]>([]);
    const servicesClient = new ServiceClient();
    const { t } = useTranslation();
    const routeNavigator = useRouteNavigator();
    const dispatch = useAppDispatch();

    useEffect(() => {
        fetchGoods();
    }, []);

    const fetchGoods = async () => {
        try {
            const serviceItems = await servicesClient.getServiceItems();
            setGoods(serviceItems);
        } catch (error) {
            console.error('Error fetching goods:', error);
        }
    };

    const openGoodsItem = (goodsItem: ServiceItem) => {
        routeNavigator.goToGoodsItem(goodsItem);
    }

    const addProductToCart = (service: ServiceItem) => {
        dispatch(addToCart(service));
    }

    const getSecondaryImage = (service: ServiceItem) => {
        if (service.images.length > 0)
            return service.images[0].imageId;

        return service.logoId;
    }

    return (
        <Box sx={{ mt: "10px" }} className={appStyleMode === AppStyle.Mobile && "goods-list"}>
            <BreadcrumbsBar paths={[{ path: GoodsRoute }]} />
            <Grid container
                sx={{
                    px: "10px",
                    width: "auto",
                    pb: "20px",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    py: "10px",
                    color: "black"
                }}>

                {goods.map((item, index) => (
                    <Grid sx={{ px: "5px", pb: "20px" }} item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Grid container flexDirection="column" sx={{ height: "100%" }}>
                            <Box
                                sx={{
                                    maxHeight: "300px",
                                    '&:hover': {
                                        cursor: item.logoId && "pointer",
                                    },
                                }}
                                onClick={() => openGoodsItem(item)}>
                                {item.logoId && (
                                    <Box sx={{
                                        position: "relative",
                                    }}>
                                        <ImageLoader
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                transition: "opacity 0.5s",
                                                '&:hover': {
                                                    opacity: 0,
                                                },
                                            }}
                                            src={`${imagesUri}/${item.logoId}?orderme-api-key=${apiKey}&height=500`}
                                            alt={item.name} />
                                        <ImageLoader
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                transition: "opacity 0.5s",
                                                opacity: 0,
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                '&:hover': {
                                                    opacity: 1,
                                                },
                                            }}
                                            src={`${imagesUri}/${getSecondaryImage(item)}?orderme-api-key=${apiKey}&height=500`}
                                            alt={item.name} />
                                    </Box>
                                )}
                            </Box>
                            <Box sx={{ textAlign: "center" }}>
                                <Typography
                                    sx={{
                                        '&:hover': {
                                            cursor: "pointer",
                                        }
                                    }}
                                    onClick={() => openGoodsItem(item)}>
                                    {item.name}
                                </Typography>
                                <Typography> {roundDoubleNumbers(item.price)} €</Typography>
                            </Box>
                            <Box sx={{ mt: "auto", textAlign: "center" }}>
                                <Button onClick={() => addProductToCart(item)}>{t('payment:addToCart')}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}