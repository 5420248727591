
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ImageNavigationBox, NavIconProps } from './carouselStyles';
import { ImageNavProps } from './ImageNavProps';

export function ImageNavPrev(props: ImageNavProps) {
    return (
        <ImageNavigationBox
            sx={{
                left: 5,
                display: props.visible ? "flex" : "none"
            }}>
            <ArrowBackIosNewIcon sx={{ ...NavIconProps }} onClick={props.onClick} />
        </ImageNavigationBox>
    )
}