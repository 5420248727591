import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShortDataLink } from "orderme-api-integration-client";

interface MenuState {
    isMenuOpened: boolean;
}

const initialState = {
    isMenuOpened: false
} as MenuState;

const menuSlice = createSlice({
    name: 'menu',
    initialState: initialState,
    reducers: {
        openMenu: state => {
            state.isMenuOpened = true;
        },
        closeMenu: state => {
            state.isMenuOpened = false;
        }
    },
})

export const { openMenu, closeMenu } = menuSlice.actions
export default menuSlice.reducer