import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import { getLanguage } from "../../../helpers/localStorageService";
import { useEffect, useState } from "react";
import { availableLanguages, defaultLanguage } from "../../../i18n/language";
import { updateLanguage } from "../../../helpers/language";
import { LanguageItem } from "../../../interfaces/interfaces";
import { appStyleMode } from "../../../helpers/clientConfigs";
import { AppStyle } from "../../../helpers/appStyle";
import { WebsiteRoutes } from "../../../routes/allRoutes";
import { matchPath, useLocation, Params } from "react-router";

export function LanguageMenu() {
    const [currentLanguage, setCurrentLanguage] = useState<LanguageItem>(defaultLanguage);
    const location = useLocation();

    useEffect(() => {
        const languageKey = getLanguage();
        const language = availableLanguages.find((lang) => lang.key === languageKey);
        if (language)
            setCurrentLanguage(language);
    }, []);

    const changeLanguage = (lang: LanguageItem) => {
        if (lang.key === currentLanguage.key) {
            setAnchorEl(null);
            return;
        }

        var route = WebsiteRoutes.find(route => {
            var path = route.getPathByLanguage(currentLanguage.key);
            return matchPath(path, location.pathname);
        });

        var matchParams: Params<string>;
        if (route) {
            var path = route.getPathByLanguage(currentLanguage.key);
            const match = matchPath(path, location.pathname);
            if (match)
                matchParams = match.params;
        }

        updateLanguage(lang)
            .then(() => {
                setCurrentLanguage(lang);
                handleClose();
                if (appStyleMode !== AppStyle.Mobile) {
                    if (route) {
                        var newPath = route.getPathByLanguage(lang.key);
                        if (matchParams) {
                            Object.keys(matchParams).forEach(key => {
                                newPath = newPath.replace(`:${key}`, matchParams[key]);
                            });
                        }
                        if (location.search) {
                            newPath += location.search;
                        }

                        window.location.href = process.env.PUBLIC_URL + newPath;
                    }
                    else {
                        window.location.reload();
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-controls={open ? 'language-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Avatar sx={{ height: "30px", width: "30px" }} alt={currentLanguage.key} src={currentLanguage.image} />
            </IconButton>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    "& .MuiMenu-paper":
                    {
                        backgroundColor: appStyleMode === AppStyle.Paulini ? "#71c1e4" : "paulini.blue"
                    },
                }}>
                {availableLanguages.map((option, index) => (
                    <MenuItem sx={{ px: "12px" }} key={index} onClick={() => changeLanguage(option)}>
                        <Avatar sx={{ height: "30px", width: "30px" }} alt={option.key} src={option.image} />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}