import CardMedia from '@mui/material/CardMedia';
import { Card, Link } from '@mui/material';
import { useRouteNavigator } from '../../routes/useRouteNavigator';

export interface BannerProps {
    image: string;
    smallImage: string;
    url: string;
}

export function Banner(bannerProps: BannerProps) {
    const routeNavigator = useRouteNavigator();

    return (
        bannerProps &&
        <Card sx={{ mb: "10px", mx: "5px" }}>
            <Link
                sx={{
                    '&:hover': {
                        cursor: "pointer",
                    }
                }}
                onClick={() => routeNavigator.goToPath(bannerProps.url)}>
                <CardMedia
                    sx={{ display: { md: 'block', xs: 'none' }, minHeight: "340px" }}
                    component="img"
                    alt="Banner image"
                    image={bannerProps.image}
                />
                <CardMedia
                    sx={{ display: { md: 'none', xs: 'block' }, minHeight: "340px" }}
                    component="img"
                    alt="Banner small image"
                    image={bannerProps.smallImage}
                />
            </Link>
        </Card >
    );
};