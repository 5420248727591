import { Box, Button, Grid, Typography } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { cartWorth } from "../../helpers/costCounter";

interface WhereToItemProps {
    title: string;
    openPage: () => void;
    icon: any;
    soon?: boolean;
    disabled?: boolean;
    description?: string[];
}

export function WhereToItem(props: WhereToItemProps) {
    const {
        title,
        openPage,
        icon,
        soon = false,
        description,
        disabled
    } = props;
    const { t } = useTranslation('payment');

    return (
        <Button disabled={soon || disabled}
            sx={{
                color: "black",
                backgroundColor: "white",
                p: "0px",
                my: "10px",
                width: "100%",
                "&:hover": {
                    backgroundColor: "rgba(235, 235, 235)"
                }
            }}
            onClick={() => openPage()}>
            <Box boxShadow={5}
                sx={{
                    borderRadius: "10px",
                    width: "100%",
                }}>
                <Grid sx={{ position: "relative", width: "100%" }}>
                    <Box sx={{ filter: soon && "grayscale(1)" }}>{icon}</Box>
                    {soon && <Typography sx={{ position: "absolute", left: "0px", right: "0px", top: "30px", transform: "rotate(15deg)", fontWeight: 900, color: "paulini.orange", fontSize: "25px" }}>{t('soon')}</Typography>}
                </Grid>
                <Typography>{title}</Typography>
                {description?.length > 0 &&
                    <Box sx={{ backgroundColor: "paulini.orange", m: "5px", px: "5px", borderRadius: "15px", color: "white", textTransform: "none" }}>
                        {description.map((desc, index) => (
                            <Typography key={index}>{desc}</Typography>
                        ))}
                    </Box>
                }
                <ChevronRightIcon sx={{ color: "gray", fontSize: "60px", opacity: 0.3, position: "absolute", right: "0px", top: "50px" }} />
            </Box>
        </Button >
    );
}
