import { useState, useEffect } from "react";
import { ServiceClient } from "../../helpers/client";
import { CreditServiceItem } from "orderme-api-integration-client";
import { BoxStyled } from "./creditsStyles"
import { Grid, Typography, Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { OrangeButton } from "../../controls/button/buttonStyles";
import { PaymentParam } from "../payment/payments";
import { RootState } from "../../redux/store";
import { useAppSelector } from "../../redux/hooks";
import { LoginDialog } from "../auth/login/loginDialog";
import { useRouteNavigator } from "../../routes/useRouteNavigator";

export function BuyCredits() {
    const routeNavigator = useRouteNavigator();
    const { t } = useTranslation();
    const serviceClient = new ServiceClient();
    const [creditServiceItems, setCreditServiceItems] = useState<CreditServiceItem[]>();
    const [selectedCreditService, setSelectedCreditService] = useState<CreditServiceItem>();
    const isUserLoggedIn: boolean = useAppSelector((state: RootState) => state.authState.isLoggedIn);
    const [openLogin, setOpenLogin] = useState(false);

    useEffect(() => {
        loadCreditServices();
    }, []);

    const loadCreditServices = async () => {
        try {
            const creditServices = await serviceClient.getCreditServices();
            setCreditServiceItems(creditServices);
        }
        catch (err) {
            console.log(err);
        }
    }

    const buyCredits = () => {
        if (!isUserLoggedIn) {
            setOpenLogin(true);
            return;
        }
        routeNavigator.goToPaymentMethods({ state: { creditService: selectedCreditService } as PaymentParam });
    };

    const isSelected = (item: CreditServiceItem) => {
        return selectedCreditService === item;
    }

    const selectCreditService = (item: CreditServiceItem) => {
        if (selectedCreditService === item)
            setSelectedCreditService(null);
        else
            setSelectedCreditService(item);
    }

    const closeLogin = () => {
        setOpenLogin(false);
    }

    return (
        creditServiceItems?.length > 0 && <Box sx={{ mt: "50px" }}>
            {creditServiceItems.map((item, index) => {
                return (
                    <BoxStyled
                        sx={{
                            backgroundColor: isSelected(item) ? "paulini.orange" : "white",
                            color: isSelected(item) ? "white" : "black"
                        }}
                        boxShadow={5}
                        onClick={() => { selectCreditService(item) }}
                        key={index}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center">

                            <Typography variant="body1" gutterBottom component="div">
                                {t('credits:creditsCount', { count: item.credits })}
                            </Typography>
                            <Typography variant="body1" gutterBottom component="div">
                                {item.price.toPrice()}
                            </Typography>
                        </Grid>
                    </BoxStyled>
                )
            })}

            <Grid container justifyContent="center" >
                <OrangeButton onClick={() => { buyCredits() }} disabled={!selectedCreditService} variant="contained">
                    {t('order:pay')}
                </OrangeButton>
            </Grid>
            <LoginDialog open={openLogin} onClose={() => closeLogin()} />
        </Box>
    );
}