import { Grid, Typography, Card, Divider } from "@mui/material";
import { RootState } from "../../redux/store";
import { CustomerInfo, DeliveryType, ServiceItem } from "orderme-api-integration-client";
import { roundDoubleNumbers } from "../../helpers/currency"
import { GrayTypography } from "./shoppingCartStyles"
import { cartWorth, getFriendsCartWorth, getCreditsUsed, getFinalPrice, isFreeDelivery, originalCartWorth, getSavings } from "../../helpers/costCounter";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../redux/hooks";
import { DeliveryInfo } from "./deliveryInfo";

export interface CartInfoProps {
    includeDelivery?: boolean;
}

export function CartInfo(props: CartInfoProps) {
    const userInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
    const deliveryService: ServiceItem = useAppSelector((state: RootState) => state.pickupState.deliveryService);
    const freeDeliverFrom = useAppSelector((state: RootState) => state.pickupState.freeDeliveryFrom);
    const deliveryType = useAppSelector((state: RootState) => state.pickupState.deliveryType);
    const isLoggedIn = useAppSelector((state: RootState) => state.authState.isLoggedIn);
    const servicesInBasket = useAppSelector((state: RootState) => state.cartState.servicesInBasket);
    const userHasCredit: boolean = userInfo?.credit > 0;
    const creditsUsed = userHasCredit ? getCreditsUsed() : 0;
    const { t } = useTranslation('payment');

    const getDeliveryPrice = () => {
        return props.includeDelivery && deliveryService && !isFreeDelivery() ?
            deliveryService.price : 0;
    }

    const canAllItemsBePaidWithCredits = () => {
        if (getDeliveryPrice() > 0 && freeDeliverFrom)
            return false;

        if (servicesInBasket?.some(service => !service.canBePaidWithCredits))
            return false;

        return true;
    }

    return (
        <Grid sx={{ marginBottom: "30px", width: "100%" }} container>
            <Card sx={{ width: "100%", padding: "10px", borderRadius: "10px" }}>
                <Grid container direction="row" justifyContent="space-between">
                    <GrayTypography>{t('originalCartWorth')} </GrayTypography>
                    <GrayTypography>{roundDoubleNumbers(originalCartWorth())} €</GrayTypography>
                </Grid>
                {getSavings() > 0 &&
                    <Grid container direction="row" justifyContent="space-between">
                        <Typography>{t('discount')}</Typography>
                        <Typography>{getSavings() > 0 ? '-' + roundDoubleNumbers(getSavings()) : 0} €</Typography>
                    </Grid>
                }
                {props.includeDelivery && deliveryService &&
                    <Grid container direction="row" justifyContent="space-between">
                        <GrayTypography>{t('delivery')}</GrayTypography>
                        <GrayTypography sx={{ textTransform: "uppercase" }}>
                            {getDeliveryPrice() > 0 ? deliveryService.price.toPrice() : t('freeDelivery')}
                        </GrayTypography>
                    </Grid>
                }
                <Grid container direction="row" justifyContent="space-between">
                    <GrayTypography>{t('cartWorth')} </GrayTypography>
                    <GrayTypography>{roundDoubleNumbers(cartWorth() + getDeliveryPrice())} €</GrayTypography>
                </Grid>

                <Divider sx={{ margin: "5px" }} />

                {userHasCredit &&
                    <>
                        <Grid container direction="row" justifyContent="space-between">
                            <GrayTypography>{t('credits')} </GrayTypography>
                            <GrayTypography>{roundDoubleNumbers(userInfo.credit)} €</GrayTypography>
                        </Grid>
                        <Grid container direction="row" justifyContent="space-between">
                            <Typography >{t('creditsUsed')}</Typography>
                            <Typography >-{roundDoubleNumbers(creditsUsed)} €</Typography>
                        </Grid>

                        {!canAllItemsBePaidWithCredits() &&
                            <>
                                <Typography sx={{ color: "paulini.error", fontSize: "16px" }}>{t('creditsCanBeUsedFor')}</Typography>
                                <Typography sx={{ color: "paulini.error", fontSize: "16px" }}>{t('creditsNowALlowed')}</Typography>
                            </>
                        }
                        <Divider sx={{ margin: "5px" }} />
                    </>
                }
                {isLoggedIn &&
                    <Grid container direction="row" justifyContent="space-between">
                        <Typography >{t('finalPrice')}</Typography>
                        <Typography >{roundDoubleNumbers(getFinalPrice(props.includeDelivery))} €</Typography>
                    </Grid>
                }
                {!isLoggedIn &&
                    <Grid container direction="row" justifyContent="space-between">
                        <Typography >{t('priceForFriends')}</Typography>
                        <Typography >{roundDoubleNumbers(getFriendsCartWorth())} €</Typography>
                    </Grid>
                }
                {props.includeDelivery &&
                    <>
                        <Divider sx={{ margin: "5px" }} />
                        <DeliveryInfo />

                        {getDeliveryPrice() > 0 && freeDeliverFrom &&
                            <Grid container sx={{ color: "paulini.error", textAlign: "center" }} direction="column">
                                <Typography> {deliveryType === DeliveryType.ForeignLocker ? t('parcelLockerFreeDeliveryFrom', { price: freeDeliverFrom.toPrice() }) : t('freeDeliveryFrom', { price: freeDeliverFrom.toPrice() })}</Typography>
                                <Typography>{t('priceUpToFreeDelivery', { price: (freeDeliverFrom - cartWorth()).toPrice() })}</Typography>
                            </Grid>
                        }
                    </>
                }
            </Card>
        </Grid>
    );
}