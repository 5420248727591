import { useEffect, useState } from 'react';
import { Box, Grid, Link, Typography } from "@mui/material";
import { InfoFeedClient } from '../../helpers/client';
import { InfoFeedOverview } from 'orderme-api-integration-client';
import { apiKey, imagesUri } from '../../helpers/clientConfigs';
import { ImageLoader } from '../imgLoader/imgLoader';
import { BreadcrumbsBar } from '../breadcrumbs/breadcrumbsBar';
import { useTranslation } from 'react-i18next';
import { NewsRoute } from '../../routes/allRoutes';
import { useRouteNavigator } from '../../routes/useRouteNavigator';

export function NewsList() {
    const [news, setNews] = useState<InfoFeedOverview[]>([]);
    const infoFeedClient = new InfoFeedClient();
    const { t } = useTranslation('news');
    const routeNavigator = useRouteNavigator();

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const response = await infoFeedClient.getAll();
            setNews(response);
        } catch (error) {
            console.error('Error fetching news:', error);
        }
    };

    const openNews = (newsItem: InfoFeedOverview) => {
        routeNavigator.goToNewsItem(newsItem.id, newsItem.title);
    }

    return (
        <Box sx={{ mt: "10px" }}>
            <BreadcrumbsBar paths={[{ path: NewsRoute }]} />
            <Grid container spacing={2}>
                {news.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                            sx={{
                                height: "400px",
                                '&:hover': {
                                    cursor: "pointer",
                                }
                            }}
                            onClick={() => openNews(item)}>
                            <ImageLoader
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                }}
                                src={`${imagesUri}/${item.imageId}?orderme-api-key=${apiKey}&height=500`}
                                alt={item.title} />
                        </Box>
                        <Box>
                            <Typography variant="h6" fontWeight="bold">{item.title}</Typography>
                            <Typography>{item.heading}</Typography>
                        </Box>
                        <Box sx={{ textAlign: "right", marginBottom: "auto" }}>
                            <Link component="button"
                                underline="hover"
                                color="white"
                                fontWeight="bold"
                                sx={{ verticalAlign: "baseline", fontSize: "1.2rem" }}
                                onClick={() => openNews(item)}>{t('readMore')}{">"}</Link>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box >
    );
}