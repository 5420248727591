import i18next from "i18next";
import { AboutUs } from "../components/aboutUs/aboutUs";
import { Account } from "../components/account/account";
import { Achievements } from "../components/achievement/achievements";
import { AddressBook } from "../components/addressBook/addressBook";
import { AllServices } from "../components/allServices/allServices";
import { AccountSettings } from "../components/auth/accountSettings/accountSettings";
import { Login } from "../components/auth/login/login";
import { Register } from "../components/auth/register/register";
import { SelectedCategory } from "../components/categories/selectedCategory";
import { Credits } from "../components/credits/credits";
import { WebsiteHomePage } from "../components/homePage/websiteHomePage";
import { Info } from "../components/info/info";
import { NewsItem } from "../components/news/newsItem";
import { NewsList } from "../components/news/newsList";
import { OrderDetails } from "../components/orderHistory/orderDetails";
import { OrderHistory } from "../components/orderHistory/orderHistory";
import { Partners } from "../components/partners/partners";
import { Payments } from "../components/payment/payments";
import { BusinessLockerSelection } from "../components/pointSelection/businessLockerSelection";
import { FilterTypes, PointSelection } from "../components/pointSelection/pointSelection";
import { SelectedPoint } from "../components/pointSelection/selectedPoint";
import { PrivacyPolicy } from "../components/policy/privacyPolicy";
import { TermsAndConditions } from "../components/policy/termsAndConditions";
import { CartSummary } from "../components/shoppingCart/cartSummary";
import { ShoppingCart } from "../components/shoppingCart/shoppingCart";
import { Support } from "../components/support/support";
import { WhereTo } from "../components/whereTo/whereTo";
import { RouteConfig, PrivateRouteConfig } from "./routeConfig";
import { GoodsList } from "../components/goods/goodsList";
import { GoodsItem } from "../components/goods/goodsItem";
import { ParcelLockers } from "../components/parcelLocker/parcelLockers";
import { MobileHomePage } from "../components/homePage/mobileHomePage";
import { SelectedGroupServices } from "../components/selectedService/selectedService";

export const HomeRoute = new RouteConfig(
    {
        lt: "/",
        en: "/"
    },
    "PAULINI drabužių valykla",
    <WebsiteHomePage />
);
export const MobileHomeRoute = new RouteConfig(
    {
        lt: "/pradzia",
        en: "/home"
    },
    "PAULINI drabužių valykla",
    <MobileHomePage />
);
export const CartRoute = new RouteConfig(
    {
        lt: "/krepselis",
        en: "/cart"
    },
    i18next.t("payment:cart"),
    <ShoppingCart />,
    "guest",
    true
);
export const RegistrationRoute = new RouteConfig(
    {
        lt: "/registracija",
        en: "/register"
    },
    i18next.t("account:registration"),
    <Register />,
    "onlyNotAuthenticated"
);
export const AchievementsRoute = new RouteConfig(
    {
        lt: "/pasiekimai",
        en: "/achievements"
    },
    i18next.t("achievement:achievements"),
    <Achievements />
);
export const SearchRoute = new RouteConfig(
    {
        lt: "/paieska",
        en: "/search"
    },
    i18next.t("services:allServices"),
    <AllServices />
);
export const AccountRoute = new RouteConfig(
    {
        lt: "/vartotojas",
        en: "/account"
    },
    i18next.t('account'),
    <Account />
);
export const RulesRoute = new RouteConfig(
    {
        lt: "/taisykles",
        en: "/terms-and-conditions"
    },
    i18next.t("info:rules"),
    <TermsAndConditions />
);
export const PrivacyPolicyRoute = new RouteConfig(
    {
        lt: "/privatumo-politika",
        en: "/privacy-policy"
    },
    i18next.t('info:privacyPolicy'),
    <PrivacyPolicy />
);
export const SalesPointsRoute = new RouteConfig(
    {
        lt: "/priemimo-skyriai",
        en: "/unit-locations"
    },
    i18next.t("salesPoint:salesPoints"),
    <PointSelection />
);
export const SalesPointDeliveryRoute = new PrivateRouteConfig(
    {
        lt: "/kur-priduosite/punktas",
        en: "/where-deliver/unit-location"
    },
    i18next.t("salesPoint:salesPoints"),
    <PointSelection filterType={FilterTypes.SalesPoint} />,
    true
);
export const BusinessLockerDeliveryRoute = new PrivateRouteConfig(
    {
        lt: "/kur-priduosite/verslo-spinta",
        en: "/where-deliver/business-locker"
    },
    i18next.t('salesPoint:businessLocker'),
    <BusinessLockerSelection />,
    true
);

export const ParcelLockerProviderRoute = new PrivateRouteConfig(
    {
        lt: "/kur-priduosite/pastomatas",
        en: "/where-deliver/parcel-locker"
    },
    i18next.t("salesPoint:parcelLocker"),
    <ParcelLockers />,
    true
);

export const AddressDeliveryRoute = new PrivateRouteConfig(
    {
        lt: "/kur-priduosite/adresas",
        en: "/where-deliver/address"
    },
    i18next.t("payment:chooseAddress"),
    <AddressBook />,
    true
);

export const SalesPointRoute = new RouteConfig(
    {
        lt: "/priemimo-skyrius",
        en: "/sales-point"
    },
    i18next.t("salesPoint:salesPoints"),
    <SelectedPoint />
);
export const PartnersRoute = new RouteConfig(
    {
        lt: "/partneriai",
        en: "/partners"
    },
    i18next.t("info:partners"),
    <Partners />
);
export const NewsItemRoute = new RouteConfig(
    {
        lt: "/akcijos-ir-naujienos/:title",
        en: "/offers-and-news/:title"
    },
    i18next.t('news:menuTitle'),
    <NewsItem />
);
export const NewsRoute = new RouteConfig(
    {
        lt: "/akcijos-ir-naujienos",
        en: "/offers-and-news"
    },
    i18next.t('news:menuTitle'),
    <NewsList />
);
export const AboutUsRoute = new RouteConfig(
    {
        lt: "/apie-mus",
        en: "/about-us"
    },
    i18next.t('info:aboutUs'),
    <AboutUs />
);
export const WhereToRoute = new PrivateRouteConfig(
    {
        lt: "/kur-priduosite",
        en: "/where-to"
    },
    i18next.t("payment:whereToDeliver"),
    <WhereTo />,
    true
);
export const AddressBookRoute = new PrivateRouteConfig(
    {
        lt: "/adresu-knyga",
        en: "/address-book"
    },
    i18next.t("address:addressBook"),
    <AddressBook />
);
export const AccountSettingsRoute = new PrivateRouteConfig(
    {
        lt: "/paskyros-nustatymai",
        en: "/account-settings"
    },
    i18next.t("account:settings"),
    <AccountSettings />
);
export const CreditsRoute = new RouteConfig(
    {
        lt: "/kreditai-ir-kuponai",
        en: "/credits-and-coupons"
    },
    i18next.t("account:creditsAndCoupons"),
    <Credits />
);
export const PaymentMethodsRoute = new PrivateRouteConfig(
    {
        lt: "/mokejimo-budai",
        en: "/payment-methods"
    },
    i18next.t("payment:paymentMethods"),
    <Payments />,
    true
);
export const OrderConfirmationRoute = new PrivateRouteConfig(
    {
        lt: "/uzsakymo-patvirtinimas",
        en: "/order-confirmation"
    },
    i18next.t("payment:orderConfirmation"),
    <CartSummary />,
    true
);
export const OrderHistoryRoute = new PrivateRouteConfig(
    {
        lt: "/uzsakymu-istorija",
        en: "/order-history"
    },
    i18next.t("order:history"),
    <OrderHistory />,
    true
);
export const OrderDetailRoute = new PrivateRouteConfig(
    {
        lt: "/uzsakymas",
        en: "/order-detail"
    },
    i18next.t("order:history"),
    <OrderDetails />,
    true
);
export const LoginRoute = new RouteConfig(
    {
        lt: "/prisijungimas",
        en: "/login"
    },
    i18next.t('account:login'),
    <Login />,
    "onlyNotAuthenticated"
);
export const SupportRoute = new RouteConfig(
    {
        lt: "/pagalba",
        en: "/support"
    },
    i18next.t('info:contactUs'),
    <Support />
);
export const CategoryRoute = new RouteConfig(
    {
        lt: "/paslaugos/:category/:categoryId",
        en: "/services/:category/:categoryId"
    },
    "",
    <SelectedCategory />
);

export const CategoryGroupRoute = new RouteConfig(
    {
        lt: "/paslaugos/:category/:group/:categoryId",
        en: "/services/:category/:group/:categoryId"
    },
    "",
    <SelectedCategory />
);

export const ServicesRoute = new RouteConfig(
    {
        lt: "/paslaugos",
        en: "/services"
    },
    "",
    <SelectedGroupServices />
);

export const InfoRoute = new RouteConfig(
    {
        lt: "/informacija",
        en: "/info"
    },
    i18next.t('info:information'),
    <Info />
);

export const GoodsRoute = new RouteConfig(
    {
        lt: "/prekes",
        en: "/goods"
    },
    i18next.t('services:products'),
    <GoodsList />
);

export const GoodsItemRoute = new RouteConfig(
    {
        lt: "/prekes/:name",
        en: "/goods/:name"
    },
    i18next.t('services:products'),
    <GoodsItem />
);

export const WebsiteRoutes: RouteConfig[] = [
    HomeRoute,
    CartRoute,
    SearchRoute,
    RegistrationRoute,
    AchievementsRoute,
    RulesRoute,
    PrivacyPolicyRoute,
    SalesPointRoute,
    SalesPointsRoute,
    SalesPointDeliveryRoute,
    BusinessLockerDeliveryRoute,
    ParcelLockerProviderRoute,
    AddressDeliveryRoute,
    PartnersRoute,
    NewsItemRoute,
    NewsRoute,
    AboutUsRoute,
    WhereToRoute,
    AddressBookRoute,
    AccountSettingsRoute,
    CreditsRoute,
    PaymentMethodsRoute,
    OrderConfirmationRoute,
    OrderHistoryRoute,
    OrderDetailRoute,
    LoginRoute,
    SupportRoute,
    CategoryRoute,
    CategoryGroupRoute,
    GoodsRoute,
    GoodsItemRoute
];

export const AppRoutes: RouteConfig[] = [
    MobileHomeRoute,
    ServicesRoute,
    AccountRoute,
    CartRoute,
    SearchRoute,
    RegistrationRoute,
    AchievementsRoute,
    SalesPointRoute,
    SalesPointsRoute,
    SalesPointDeliveryRoute,
    BusinessLockerDeliveryRoute,
    ParcelLockerProviderRoute,
    AddressDeliveryRoute,
    PartnersRoute,
    InfoRoute,
    WhereToRoute,
    AddressBookRoute,
    AccountSettingsRoute,
    CreditsRoute,
    PaymentMethodsRoute,
    OrderConfirmationRoute,
    OrderHistoryRoute,
    OrderDetailRoute,
    SupportRoute,
    GoodsItemRoute
];

