
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ImageNavigationBox, NavIconProps } from './carouselStyles';
import { ImageNavProps } from './ImageNavProps';

export function ImageNavNext(props: ImageNavProps) {
    return (
        <ImageNavigationBox
            sx={{
                right: 5,
                display: props.visible ? "flex" : "none"
            }}>
            <ArrowForwardIosIcon sx={{ ...NavIconProps }} onClick={props.onClick} />
        </ImageNavigationBox>
    )
}