import latinize from 'latinize';

/**
 * Generates a clean URL slug from a given string.
 * @param name - The input string to be converted to a slug.
 * @returns The generated slug.
 */
export const generateSlug = (name: string): string => {
    if (!name) return '';

    return latinize(name)
        .replace(/\u200E/g, '') // Remove left-to-right mark
        .replace(/"/g, '') // Remove double quotes
        .replace(/\?/g, '') // Remove question marks
        .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters
        .trim() // Trim leading and trailing spaces
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .toLowerCase(); // Convert to lowercase
};