import { useEffect, useState } from "react";
import { Grid, Card, ListItemButton, Box, Autocomplete, TextField } from "@mui/material";
import { NearestParcelLocker, ParcelLockerProvider } from "orderme-api-integration-client";
import { createParcelLockersClient, } from "../../helpers/client"
import { NavBar } from "../appBars/navBar/navBar";
import { Linear } from "../progressBars/linear"
import { ImageLoader } from "../imgLoader/imgLoader";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { useRouteNavigator } from "../../routes/useRouteNavigator";
import { CartRoute, ParcelLockerProviderRoute } from "../../routes/allRoutes";
import { useTranslation } from "react-i18next";
import { ParcelLockerSelection } from "./parcelLockerSelection";
import { StickyCartBar } from "../appBars/cartBar/stickyCartBar";
import { deliverToParcelLocker } from "../../redux/reducers/pickupReducer";
import { useAppDispatch } from "../../redux/hooks";
import CircularProgress from '@mui/material/CircularProgress';
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";

export function ParcelLockers() {
  const routeNavigator = useRouteNavigator();
  const dispatch = useAppDispatch();
  const parcelLockersClient = createParcelLockersClient();
  const { t } = useTranslation('salesPoint');
  const [providers, setProviders] = useState<ParcelLockerProvider[]>();
  const [selectedProvider, setSelectedProvider] = useState<ParcelLockerProvider>();
  const [parcelLockers, setParcelLockers] = useState<NearestParcelLocker[]>();
  const [cities, setCities] = useState<string[]>();
  const [selectedCity, setSelectedCity] = useState<string>();
  const [filteredParcelLockers, setFilteredParcelLockers] = useState<NearestParcelLocker[]>();
  const [selectedParcelLocker, setSelectedParcelLocker] = useState<NearestParcelLocker>();
  const [isCitiesLoading, setIsCitiesLoading] = useState<boolean>(false);

  useEffect(() => {
    parcelLockersClient.getProviders()
      .then((response) => {
        if (response) {
          setProviders(response);
          if (response.length == 1)
            selectProvider(response[0]);
        } else {
          setProviders([]);
        }
      }).catch((err) => {
        console.error(err);
        setProviders([]);
      })
  }, []);

  useEffect(() => {
    if (selectedProvider)
      getNearestParcelLockers(selectedProvider.id);
  }, [selectedProvider]);

  useEffect(() => {
    if (parcelLockers?.length > 0) {
      var lockerCities = parcelLockers.map(locker => locker.value.city);
      var uniqueCities = Array.from(new Set(lockerCities)).sort((a, b) => a.localeCompare(b));
      setCities(uniqueCities);
      setIsCitiesLoading(false);
    }
  }, [parcelLockers]);

  useEffect(() => {
    if (selectedCity) {
      setFilteredParcelLockers(parcelLockers.filter(locker => locker.value.city === selectedCity));
    }
    else {
      setSelectedParcelLocker(null);
      setFilteredParcelLockers([]);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (filteredParcelLockers?.length == 1) {
      setSelectedParcelLocker(filteredParcelLockers[0]);
    }
    else {
      setSelectedParcelLocker(null);
    }
  }, [filteredParcelLockers]);

  useEffect(() => {
    if (!selectedParcelLocker)
      return;

    dispatch(deliverToParcelLocker({ provider: selectedProvider.id, parcelLocker: selectedParcelLocker }));
  }, [selectedParcelLocker]);

  const selectProvider = (provider: ParcelLockerProvider) => {
    setSelectedProvider(provider);
  };

  const getNearestParcelLockers = (providerId: string) => {
    setIsCitiesLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => getParcelLockersByLocation(providerId, position.coords.longitude, position.coords.latitude),
      (error) => {
        console.log(error.message);
        if (error.PERMISSION_DENIED) {
          getParcelLockers(providerId);
        }
      });
  }

  const getParcelLockers = (providerId: string) => {
    parcelLockersClient.getAll(providerId)
      .then((response) => {
        var lockers = response.map(locker => new NearestParcelLocker(
          {
            value: locker,
            distanceInDegrees: undefined,
            distanceInMeters: undefined
          }
        ));
        lockers.sort((a, b) => a.value.name.localeCompare(b.value.name));
        setParcelLockers(lockers);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getParcelLockersByLocation = (providerId: string, longitude: number, latitude: number) => {
    parcelLockersClient.getByLocation(providerId, longitude, latitude).then((response) => {
      const sortedByDistance = response
        .sort((a, b) => a.distanceInMeters - b.distanceInMeters);
      setParcelLockers(sortedByDistance);
    })
      .catch((err) => {
        console.log(err);
      });
  }

  const isCartBarDisabled = () => !selectedParcelLocker;
  const goToOrderConfirmation = () => {
    routeNavigator.goToOrderConfirmation({ replace: true });
  }

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('parcelLocker')} />
      {!providers && (<Linear />)}
      <BreadcrumbsBar paths={[{ path: CartRoute, isLink: true }, { path: ParcelLockerProviderRoute }]} />
      <Grid container
        sx={{
          px: "10px",
          maxWidth: "1000px",
          minWidth: "300px",
          pb: "20px",
          backgroundColor: "white",
          borderRadius: "15px",
          py: "10px",
          color: "black"
        }}>
        {providers &&
          <Grid
            container
            direction="row"
            sx={{
              mt: "5px",
              justifyContent: "space-evenly",
              alignItems: "center"
            }}>
            {providers.map((provider, index) => {
              return (
                <Card sx={{
                  width: "300px", height: "70px", m: "10px", borderRadius: "15px",
                  border: selectedProvider == provider && "2px solid orange"
                }}>
                  <ListItemButton
                    sx={{
                      height: "100%"
                    }}
                    onClick={() => selectProvider(provider)}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <ImageLoader
                        sx={{ height: "60px", marginLeft: "5px" }}
                        src={provider.logoUrl}
                        alt={provider.name} />
                    </Grid>
                  </ListItemButton>
                </Card>);
            })}
          </Grid>
        }
        {selectedProvider &&
          <Autocomplete
            disablePortal
            options={cities ?? []}
            disabled={isCitiesLoading}
            loading={isCitiesLoading}
            getOptionLabel={(option) => option}
            onChange={(_, option) => setSelectedCity(option)}
            sx={{ width: "100%", m: "15px", '& fieldset': { borderRadius: "15px" } }}
            renderInput={(params) =>
              <TextField {...params} label={t('selectCity')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isCitiesLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />}
          />}
        {filteredParcelLockers?.length > 0 &&
          <ParcelLockerSelection
            filteredDeliveryDetails={filteredParcelLockers}
            value={selectedParcelLocker}
            onSelect={(locker) => setSelectedParcelLocker(locker)} />
        }
        {appStyleMode === AppStyle.Mobile ?
          <BottomCartBar isPurchase={true}
            isDisabled={isCartBarDisabled()}
            open={goToOrderConfirmation}
          />
          :
          <Box sx={{ width: "100%", my: "15px" }}>
            <StickyCartBar sx={{ mx: "16px" }} isPurchase={true}
              isDisabled={isCartBarDisabled()}
              open={goToOrderConfirmation} />
          </Box>
        }
      </Grid>
    </Grid >
  );
}