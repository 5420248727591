import { Box, Grid, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ServiceItem } from "orderme-api-integration-client";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { roundDoubleNumbers } from "../../helpers/currency";
import { addToCart, removeByOneFromCart } from "../../redux/reducers/cartReducer";

interface StickyProductCartBoxProps {
    service: ServiceItem;
    visible: boolean;
}

export function StickyProductCartBox(props: StickyProductCartBoxProps) {
    const { service, visible } = props;
    const dispatch = useAppDispatch();
    const servicesInBasket = useAppSelector(state => state.cartState.servicesInBasket);
    const serviceCount = service ? servicesInBasket.filter(item => service.id === item.id).length : 0;

    const addProductToCart = (service: ServiceItem) => {
        dispatch(addToCart(service));
    }

    const removeFromBasket = (service: ServiceItem) => {
        dispatch(removeByOneFromCart(service));
    }

    return (
        <Box
            sx={{
                display: !visible && "none",
                position: "sticky",
                bottom: 0,
                zIndex: 1,
                backgroundColor: "white"
            }}>
            <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}> {roundDoubleNumbers(service.price)} €</Typography>
            <Grid sx={{ width: "200px", mx: "auto" }} container direction="row" justifyContent="space-around">
                <IconButton onClick={() => removeFromBasket(service)}>
                    <RemoveCircleOutlineIcon sx={{ height: "40px", width: "40px", color: "orange" }} />
                </IconButton>
                <Typography sx={{ marginTop: "15px", fontWeight: "bold", fontSize: "25px" }}>
                    {serviceCount}
                </Typography>
                <IconButton onClick={() => addProductToCart(service)}>
                    <AddCircleOutlineIcon sx={{ height: "40px", width: "40px", color: "orange" }} />
                </IconButton>
            </Grid>
        </Box>
    )
}