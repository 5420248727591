import { useEffect, useState } from "react"
import { SettingClient } from "../../../helpers/client"
import { useLocation } from "react-router";
import { Box, Typography } from "@mui/material";
import { useAppDispatch } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { appStyleMode, basePath } from "../../../helpers/clientConfigs";
import * as externalLogin from "../../../helpers/externalLogin";
import { Spinner } from "../../spinner/spinner";
import { AppStyle } from "../../../helpers/appStyle";
import { LoginBoxStyleMobile, LoginBoxStyleWebsite } from "../login/loginStyles";
import { authenticateFromApple } from "../../../redux/reducers/authReducer";
import { SignInProps } from "../../../interfaces/interfaces";
import { setUrlBeforeLogin } from "../../../helpers/localStorageService";
import AppleIcon from '@mui/icons-material/Apple';
import { WhiteButton } from "../../../controls/button/buttonStyles";
import { ExternalAuthenticationRequest } from "orderme-api-integration-client";
import { useRouteNavigator } from "../../../routes/useRouteNavigator";

export function AppleSignIn(props: SignInProps) {
    const dispatch = useAppDispatch();
    const routeNavigator = useRouteNavigator();
    const location = useLocation();
    const { t } = useTranslation('account');
    const [clientId, setClientId] = useState("");
    const settingClient: SettingClient = new SettingClient();
    const [isBusy, setIsBusy] = useState(false);

    const handleAppleSignIn = async (accessToken?: string, state?: string, code?: string, firstName?: string, lastName?: string) => {
        const appleState = externalLogin.getAppleState();
        externalLogin.removeAppleState();

        var appleToken = externalLogin.getAppleTokenProps(accessToken);

        if (!accessToken || !code || state !== appleState?.state || appleToken?.nonce !== appleState.nonce) {
            if (props.onError) {
                props.onError("Apple OAuth signIn failed");
            }
            return;
        }

        try {
            const authRequest = { accessToken: accessToken, state: state, code: code } as ExternalAuthenticationRequest;
            await dispatch(authenticateFromApple(authRequest)).unwrap();
            if (props.onSignedIn)
                props.onSignedIn();
        }
        catch (err) {
            console.log(err);
            const registrationProps = externalLogin.getAppleRegistrationProps(accessToken, state, code, firstName, lastName);
            routeNavigator.goToRegistration({ state: registrationProps, replace: true }
            );
        }
    };

    useEffect(() => {
        if (clientId !== "") {
            return;
        }

        (async () => {
            let setting = await settingClient.get();

            if (!setting?.authentication?.appleClientId) {
                return;
            }

            setClientId(setting.authentication.appleClientId);
        })();
    }, [clientId]);

    const onSignedIn = async (event: any) => {
        try {
            var data = event.data;

            if (typeof data === 'string') {
                data = JSON.parse(event.data.substring(7));
            }

            window.removeEventListener('message', onSignedIn);

            await handleAppleSignIn(data.access_token, data.state, data.code, data.firstName, data.lastName);
        }
        finally {
            setIsBusy(false);
        }
    }

    const signing = () => {
        setIsBusy(true);

        const state = externalLogin.randomState();
        const nonce = externalLogin.randomNonce();
        externalLogin.saveAppleState(state, nonce);

        let redirectUrl = basePath + '/signin-apple';
        let endpoint = `https://appleid.apple.com/auth/authorize?` +
            `&client_id=${clientId}` +
            `&scope=name email` +
            `&redirect_uri=${redirectUrl}` +
            `&state=${state}` +
            `&nonce=${nonce}` +
            `&response_type=code id_token` +
            `&response_mode=form_post` +
            `&usePopup=false`;

        if (appStyleMode === AppStyle.Mobile) {
            window.addEventListener('message', onSignedIn);
            window.open(endpoint, 'oauth:apple', '');
        }
        else {
            setUrlBeforeLogin(location.pathname);
            window.location.replace(endpoint);
        }
    }

    return (props.isVisible &&
        <Box sx={appStyleMode === AppStyle.Mobile ? LoginBoxStyleMobile : LoginBoxStyleWebsite}>
            <WhiteButton disabled={isBusy}
                sx={{
                    width: "220px",
                    textTransform: "none",
                    justifyContent: "flex-start",
                    pl: "12px !important",
                    pr: "0px",
                    m: "0.2em"
                }}
                onClick={signing} startIcon={<AppleIcon />}>
                <Typography sx={{ pl: "3px", fontSize: "16px" }}>{t('loginApple')}</Typography>
            </WhiteButton>
            <Spinner isActive={isBusy} />
        </Box >);
}