import { useEffect, useState } from "react";
import { Grid, IconButton, Box } from "@mui/material";
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { ServiceLogoItem } from "../serviceLogoItem/serviceLogoItem";
import { ServicesList } from "./servicesList";
import { SelectedGroupServicesProps } from "../../interfaces/interfaces";
import { useRouteNavigator } from "../../routes/useRouteNavigator";

export function SelectedGroupServices() {
  const routeNavigator = useRouteNavigator();
  const location = useLocation();
  const [serviceContent, setServiceContent] = useState<SelectedGroupServicesProps>();

  useEffect(() => {
    if (location.state) {
      setServiceContent(location.state as SelectedGroupServicesProps);
    }
    else {
      routeNavigator.goHome(true);
    }
  }, [location.state]);

  return serviceContent && (
    <Grid sx={{ overflow: "hidden" }}>
      <IconButton sx={{ position: "absolute", mt: "5px", left: "0px", zIndex: 2 }} onClick={() => routeNavigator.goBack()}>
        <ArrowBackIcon sx={{ color: "white" }} />
      </IconButton>
      <Box className="service-group-image">
        <ServiceLogoItem service={serviceContent.serviceGroup} />
      </Box>
      <ServicesList serviceGroup={serviceContent.serviceGroup} serviceCategory={serviceContent.serviceCategory} />
      <BottomCartBar />
    </Grid>
  );
}