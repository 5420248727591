import { useEffect, useState } from "react";
import { LoginDialog } from "../auth/login/loginDialog"
import { HashRouter } from "react-router-dom"
import { RootState } from '../../redux/store';
import GetParameterPopups from '../../popups';
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { resetLoginRequired } from "../../redux/reducers/authReducer"
import { LoadFailed } from "../loadFailed/loadFailed"
import MobileRoutes from "../../routes/mobileRoutes"
import { CookiesBar } from "../cookies/cookiesBar";
import { VersionUpdate } from "../version/versionUpdate";

declare let window: any;

// iOS permissions
enum TrackingPermission {
    NOT_DETERMINED = 0, // User has not yet received an authorization request to authorize access to IDFA
    RESTRICTED = 1, // User restricted the value returned if authorization to access IDFA
    DENIED = 2, // The value returned if the user denies authorization to access IDFA
    AUTHORIZED = 3 // The value returned if the user authorizes access to IDFA
}

function MobileContent() {
    var dispatch = useAppDispatch();
    const [showCookiesBar, setShowCookiesBar] = useState(false);
    const isDisconnected: boolean = useAppSelector((state: RootState) => state.appLoaderState.isDisconnected);
    const isLoginRequired: boolean = useAppSelector((state: RootState) => state.authState.isLoginRequired);
    const isLatestAppVersion: boolean = useAppSelector((state: RootState) => state.appLoaderState.isLatestAppVersion);
    const isLoaded: boolean = useAppSelector((state: RootState) => state.appLoaderState.isLoaded);
    const isPlatformIOS = window.cordova && window.cordova.platformId.toString().toLowerCase() === "ios";

    useEffect(() => {
        if (!isLoaded)
            return;

        if (isPlatformIOS) {
            readTrackingPermission();
        }
        else {
            setShowCookiesBar(true);
        }
    }, [isLoaded])

    const closeLogin = () => {
        dispatch(resetLoginRequired());
    }

    const readTrackingPermission = () => {
        if (isPlatformIOS)
            window.cordova.exec(readTrackingSuccessCallback, readTrackingFailedCallback, 'idfa', "getInfo", []);
    }

    const readTrackingSuccessCallback = (trackingResult: any) => {
        if (trackingResult.trackingPermission !== undefined) {
            if (trackingResult.trackingPermission === TrackingPermission.NOT_DETERMINED) {
                // Request permissions
                requestTrackingPermission();
            }
            else if (trackingResult.trackingPermission === TrackingPermission.AUTHORIZED) {
                window.cordova.plugins.firebase.analytics.setEnabled(true);
            }
            else {
                window.cordova.plugins.firebase.analytics.setEnabled(false);
            }
        }
        else {
            // If tracking permission is missing, then show cookies bar (iOS < v14)
            setShowCookiesBar(true);
        }
    }

    const readTrackingFailedCallback = () => {
        setShowCookiesBar(true);
    }

    const requestTrackingPermission = () => {
        if (isPlatformIOS) {
            window.cordova.exec(requestTrackingPermissionCallback, function () { }, 'idfa', "requestPermission", []);
        }
    }

    const requestTrackingPermissionCallback = (trackingResult: any) => {
        if (trackingResult === TrackingPermission.AUTHORIZED) {
            window.cordova.plugins.firebase.analytics.setEnabled(true);
        }
    }

    return (isDisconnected ?
        <LoadFailed /> :
        !isLatestAppVersion ?
            <VersionUpdate /> :
            <HashRouter>
                <MobileRoutes />
                <GetParameterPopups />
                <LoginDialog open={isLoginRequired} onClose={closeLogin} />
                {showCookiesBar && <CookiesBar />}
            </HashRouter>
    )
}

export default MobileContent;
