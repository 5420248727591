import { Grid, ImageListItem, ImageListItemBar, ListItem, SxProps, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { apiKey, appStyleMode, imagesUri } from "../../helpers/clientConfigs";
import tag from "../../logo/tag.png"
import { ServiceGroup } from "orderme-api-integration-client";
import { ImageLoader } from "../imgLoader/imgLoader"
import { DiscountImage, DiscountTypography, ImageNavigationBox } from "./serviceLogoItemStyles";
import i18next from "i18next";
import { AppStyle } from "../../helpers/appStyle";
import { useEffect, useState } from "react";
import { Theme } from "@emotion/react";

interface ServiceLogoItemProps {
  service: ServiceGroup;
  isButton?: boolean;
  open?: () => void;
  imageWidth?: number;
}

declare let window: any;

export function ServiceLogoItem(props: ServiceLogoItemProps) {
  const { service, isButton, open, imageWidth } = props;
  const [imageId, setImageId] = useState<string>();
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    if (imageIndex > 0 && imageIndex <= service.images?.length) {
      setImageId(service.images[imageIndex - 1].imageId);
    }
    else
      setImageId(service.logoId);
  }, [imageIndex])

  const toggleButton = () => {
    if (isButton) {
      return true as true;
    }
  }

  const GetImageWidth = () => {
    if (imageWidth)
      return imageWidth;
    return appStyleMode === AppStyle.Mobile && window.innerWidth > 500 ? window.innerWidth : 500;
  }

  const getServicePrice = () => (service.pricesStartFrom ?? 0).toPrice();

  const showNextImage = () => {
    if (imageIndex < service.images?.length)
      setImageIndex(imageIndex + 1);
    else
      setImageIndex(0);
  }

  const showPreviousImage = () => {
    if (imageIndex > 0)
      setImageIndex(imageIndex - 1);
    else
      setImageIndex(service.images?.length);
  }

  const navIconProps = ({
    height: "40px",
    color: "paulini.orange",
    width: "40px",
    opacity: 0.5,
    '&:hover': {
      cursor: "pointer",
      opacity: 0.8
    }
  }) as SxProps<Theme>;

  return (
    imageId &&
    <ImageListItem sx={{
      mb: appStyleMode === AppStyle.Mobile ? "3px" : null,
      width: "100%",
      height: "100% !important",
    }}>
      <ImageLoader sx={{ display: "block", position: "absolute", objectFit: "cover", height: "100%", width: "100%" }}
        src={`${imagesUri}/${imageId}?orderme-api-key=${apiKey}&width=${GetImageWidth()}`}
        alt={service.name} />
      {service.images?.length > 0 && (
        <>
          <ImageNavigationBox sx={{ left: 0 }}>
            <ArrowBackIosIcon sx={{ ...navIconProps, pl: "10px" }} onClick={showPreviousImage} />
          </ImageNavigationBox>
          <ImageNavigationBox sx={{ right: 0 }}>
            <ArrowForwardIosIcon sx={navIconProps} onClick={showNextImage} />
          </ImageNavigationBox>
        </>
      )}
      <ListItem sx={{ width: "100%", height: "100%" }} button={toggleButton()} onClick={() => isButton && open()}>
        {service.maxDiscount && (
          <>
            <DiscountImage alt="" src={tag} />
            <DiscountTypography> -{service.maxDiscount} %</DiscountTypography>
          </>
        )}

        <ImageListItemBar position="bottom"
          sx={{
            background:
              'linear-gradient(to top, rgba(0,0,0,0.5) 0%, ' +
              'rgba(0,0,0,0.15) 60%, rgba(0,0,0,0) 100%)',
          }}
          title={
            <Grid container direction="row" justifyContent="space-between">
              <Typography>{service.name}</Typography>
              <Typography sx={{ fontWeight: "lighter" }}>
                {service.numberOfServices > 1 ?
                  i18next.t('services:pricesFrom', { price: getServicePrice() }) : getServicePrice()
                }</Typography>
            </Grid>} />
      </ListItem>
    </ImageListItem>
  );
}