import { Box } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import { styled } from '@mui/system';

interface Props {
    content: string;
}

const HtmlViewerContainer = styled(Box)({
    color: "black",
    fontFamily: '"Roboto", sans-serif',
    ".ql-video": {
        width: "800px",
        height: "450px",
        borderRadius: "15px",
        '@media (max-width: 800px)': {
            width: "600px",
            height: "340px",
        },
        '@media (max-width: 600px)': {
            width: "400px",
            height: "225px",
        },
        '@media (max-width: 400px)': {
            width: "300px",
            height: "165px",
        },
    },
    "img": {
        maxWidth: "100%"
    }
});

export function HtmlViewer(props: Props) {
    const { content } = props;

    return (
        <HtmlViewerContainer className='ql-editor' dangerouslySetInnerHTML={{ __html: content }} />)
}