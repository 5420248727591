import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerAddress, DeliveryType, DestinationAddress, ForeignLockerDestination, NearestParcelLocker, OrderDestination, ServiceItem } from "orderme-api-integration-client";

export interface DeliveryState {
    orderDestination: OrderDestination,
    deliveryType: DeliveryType,
    freeDeliveryFrom: number,
    deliveryService: ServiceItem,
    parcelLocker: NearestParcelLocker
}

const initialState = {
    orderDestination: {} as OrderDestination,
    freeDeliveryFrom: 0,
    deliveryService: null as ServiceItem,
    deliveryType: null as DeliveryType,
    parcelLocker: null as NearestParcelLocker
}

const pickupSlice = createSlice({
    name: 'pickup',
    initialState: initialState,
    reducers: {
        deliverToSalesPoint: (state, action: PayloadAction<{ salesPointId?: string }>) => {
            if (action.payload?.salesPointId) {
                var orderDestination = {
                    knownId: action.payload.salesPointId
                } as OrderDestination;
                state.orderDestination = orderDestination;
            }
            else
                state.orderDestination = {} as OrderDestination;

            state.deliveryType = DeliveryType.SalesPoint;
        },
        deliverToAddress: (state, action: PayloadAction<CustomerAddress>) => {
            var destinationAddress = {
                fullAddress: action.payload.address,
                apartmentNumber: action.payload.apartmentNumber
            } as DestinationAddress;

            var orderDestination = {
                address: destinationAddress
            } as OrderDestination;
            state.orderDestination = orderDestination;
            state.deliveryType = DeliveryType.Address;
        },
        deliverToBusinessLocker: (state, action: PayloadAction<{ lockerId: string }>) => {
            var orderDestination = {
                knownId: action.payload.lockerId
            } as OrderDestination;
            state.orderDestination = orderDestination;
            state.deliveryType = DeliveryType.Wardrobe;
        },
        deliverToParcelLocker: (state, action: PayloadAction<{ provider: string, parcelLocker: NearestParcelLocker }>) => {
            var destination = {
                lockerId: action.payload.parcelLocker.value.id,
                provider: action.payload.provider
            } as ForeignLockerDestination;

            var orderDestination = {
                parcelLocker: destination
            } as OrderDestination;
            state.orderDestination = orderDestination;
            state.parcelLocker = action.payload.parcelLocker;
            state.deliveryType = DeliveryType.ForeignLocker;
        },
        updateDeliveryService: (state, action: PayloadAction<ServiceItem>) => {
            state.deliveryService = action.payload;
        },
        updateFreeDeliveryFrom: (state, action: PayloadAction<number>) => {
            state.freeDeliveryFrom = action.payload;
        },
        clearDelivery: () => initialState
    },
})

export const
    {
        deliverToSalesPoint,
        deliverToParcelLocker,
        deliverToAddress,
        deliverToBusinessLocker,
        updateDeliveryService,
        updateFreeDeliveryFrom,
        clearDelivery
    } = pickupSlice.actions
export default pickupSlice.reducer