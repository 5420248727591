import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LinksClient, ShortDataLink, ShortDataLinkType } from "orderme-api-integration-client";
import { createLinksClient } from "../../helpers/client";

interface LinksState {
    news: ShortDataLink[],
    categories: ShortDataLink[],
    groups: ShortDataLink[],
    physicalItems: ShortDataLink[],
    loaded: boolean
}

const linksClient: LinksClient = createLinksClient();

export const loadShortDataLinks = createAsyncThunk(
    'links/load',
    async (_: void, thunkAPI) => {
        try {
            var infoFeedLinksRequest = linksClient.getShortenByType(ShortDataLinkType.InfoFeed);
            var categoryLinksRequest = linksClient.getShortenByType(ShortDataLinkType.ServiceCategory);
            var groupLinksRequest = linksClient.getShortenByType(ShortDataLinkType.ServiceGroup);
            var physicalItemLinksRequest = linksClient.getShortenByType(ShortDataLinkType.ServicePhysicalItem);
            const [infoFeedLinks, categoryLinks, groupLinks, physicalItemLinks] = await Promise.all([
                infoFeedLinksRequest,
                categoryLinksRequest,
                groupLinksRequest,
                physicalItemLinksRequest
            ]);

            thunkAPI.dispatch(updateNewsLinks(infoFeedLinks));
            thunkAPI.dispatch(updateCategoryLinks(categoryLinks));
            thunkAPI.dispatch(updateGroupLinks(groupLinks));
            thunkAPI.dispatch(updatePhysicalItemLinks(physicalItemLinks));
        }
        catch (error) {
            console.error(error);
        }

        return true;
    },
)

const initialState = {
    news: [] as ShortDataLink[],
    categories: [] as ShortDataLink[],
    groups: [] as ShortDataLink[],
    physicalItems: [] as ShortDataLink[],
    loaded: false
} as LinksState;

const linksSlice = createSlice({
    name: 'links',
    initialState: initialState,
    reducers: {
        updateNewsLinks: (state, action: PayloadAction<ShortDataLink[]>) => {
            state.news = action.payload;
        },
        updateCategoryLinks: (state, action: PayloadAction<ShortDataLink[]>) => {
            state.categories = action.payload;
        },
        updateGroupLinks: (state, action: PayloadAction<ShortDataLink[]>) => {
            state.groups = action.payload;
        },
        updatePhysicalItemLinks: (state, action: PayloadAction<ShortDataLink[]>) => {
            state.physicalItems = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadShortDataLinks.fulfilled, (state, action) => {
            state.loaded = true;
        });
        builder.addCase(loadShortDataLinks.pending, (state) => {
            state.loaded = false;
        });
        builder.addCase(loadShortDataLinks.rejected, (state) => {
            state.loaded = true;
        });
    },
})

export const { updateNewsLinks, updateCategoryLinks, updateGroupLinks, updatePhysicalItemLinks } = linksSlice.actions
export default linksSlice.reducer