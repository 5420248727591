import i18next from "i18next";
import { LanguageItem } from "../interfaces/interfaces";
import { setLanguage } from "./localStorageService";
import { updateClientLanguage } from "./client";
import { refreshCartServices } from "./cartVerifier";
import latinize from "latinize";

export const updateLanguage = async (language: LanguageItem) => {
    try {
        await i18next.changeLanguage(language.key);
        setLanguage(language);
        updateClientLanguage(language);
        refreshCartServices();
    } catch (error) {
        console.error("Not possible to update language", error);
        throw new Error("Not possible to update language");
    }
}

export const getUrlCode = (name: string) =>
    name ? latinize(name
        .replace(/\u200E/g, "")
        .replace(/"/g, "")
        .trim()
        .replace(/ /g, "-").toLowerCase()) : "";