import { SetStateAction, useEffect, useState } from "react";
import { Icon, Grid, InputAdornment, ListItem, MenuItem, Typography, Box, ListItemIcon } from "@mui/material";
import { NavBar } from "../../appBars/navBar/navBar"
import { PasswordChange } from "./passwordChange"
import { EmailChange } from "./emailChange"
import { MobileChange } from "./mobileChange"
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomerInfo, CustomerLoginInfo, ExternalAuthenticationType, UpdateInfoRequest } from "orderme-api-integration-client";
import { CustomerClient } from "../../../helpers/client"
import SendIcon from '@mui/icons-material/Send';
import { ConfirmPhone } from "./phoneConfirmation"
import { AccSettingsList, CancelButton, CenterListItem, ChangeInfoButton, PurpleButton, RevokeIcon, SaveButton } from "./accountSettingsStyles"
import { Linear } from "../../progressBars/linear"
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import WcIcon from '@mui/icons-material/Wc';
import CreateIcon from '@mui/icons-material/Create';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CakeIcon from '@mui/icons-material/Cake';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from "../../../logo/google.svg"
import { InputField } from "../../inputField/inputField";
import { CustomerSwitch } from "./customerSwitch";
import { CustomerDelete } from "./customerDelete";
import { getCustomerNumber, getLastEmailConfirm, getLastPhoneConfirmTime, removeLastEmailConfirm, removeLastPhoneConfirm, setLastEmailConfirm, setLastPhoneConfirmTime } from "../../../helpers/localStorageService";
import { genderItems, getGender, getGenderLabel, getGenderType } from "../../../helpers/genderItems";
import { useTranslation } from 'react-i18next';
import { Spinner } from "../../spinner/spinner";
import moment from "moment";
import { OrangeButton, RedButton } from "../../../controls/button/buttonStyles";
import { appStyleMode } from "../../../helpers/clientConfigs";
import { AppStyle } from "../../../helpers/appStyle";
import { BreadcrumbsBar } from "../../breadcrumbs/breadcrumbsBar";
import { waitPhoneConfirmation } from "../../../redux/reducers/customerReducer";
import { useAppDispatch } from "../../../redux/hooks";
import { CheckBox } from "../../checkBox/checkBox";
import { ExternalLoginRevoke } from "./externalLoginRevoke";
import Tooltip from '@mui/material/Tooltip';
import { AccountSettingsRoute, AddressBookRoute } from "../../../routes/allRoutes";
import { useRouteNavigator } from "../../../routes/useRouteNavigator";

enum SendType {
  Sms,
  Mail
}

export function AccountSettings() {
  const dispatch = useAppDispatch();
  const routeNavigator = useRouteNavigator();
  const [fieldsDisabled, setFieldsDisabled] = useState(true);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openChangeEmail, setOpenChangeEmail] = useState(false);
  const [openChangeMobile, setOpenChangeMobile] = useState(false);
  const [openConfirmPhone, setOpenConfirmPhone] = useState(false);
  const [openCustomerSwitch, setOpenCustomerSwitch] = useState(false);
  const [openCustomerDelete, setOpenCustomerDelete] = useState(false);
  const [openRevokeLogin, setOpenRevokeLogin] = useState(false);
  const [revokeExternalLoginType, setRevokeExternalLoginType] = useState<ExternalAuthenticationType>();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const customersClient: CustomerClient = new CustomerClient();
  const [userInfo, setUserInfo] = useState<CustomerInfo>();
  const [clientInfo, setClientInfo] = useState<CustomerLoginInfo>();
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isCommunicationApproved, setIsCommunicationApproved] = useState(false);

  const { t } = useTranslation('account');

  useEffect(() => {
    getCustomerInfo();
  }, []);

  useEffect(() => {
    if (clientInfo) {
      checkButtonState();
    }
  }, [clientInfo]);

  const checkButtonState = () => {
    console.log(getLastPhoneConfirmTime(), getLastEmailConfirm())
    if (getLastPhoneConfirmTime()) {
      activateTimeout(SendType.Sms);
    }

    if (getLastEmailConfirm()) {
      activateTimeout(SendType.Mail);
    }
  }

  const getCustomerInfo = () => {
    customersClient.getInfo().then((response) => {
      setUserData(response);
    })
      .catch((err) => {
        console.log(err);
      });
  }

  const setUserData = (info: CustomerLoginInfo) => {
    setClientInfo(info);
    const customer = info.customers[getCustomerNumber()];
    setUserInfo(customer);
    if (customer?.communicationAgreedOn)
      setIsCommunicationApproved(true);
    else
      setIsCommunicationApproved(false);
  }

  const handleFieldChange = (e: any) => {
    const { name, type } = e.target;
    let value = e.target.value;

    if (type === 'date' && value) {
      value = moment.utc(value);
    }
    else if (name === 'gender') {
      value = getGenderType(value);
    }

    const fieldValue = { [name]: value } as Pick<UpdateInfoRequest, keyof UpdateInfoRequest>;

    setUserInfo({
      ...userInfo,
      ...fieldValue
    });
  }

  const toggleApproveCommunication = () => {
    if (!fieldsDisabled)
      setIsCommunicationApproved(!isCommunicationApproved);
  }

  const changeInfo = async () => {
    try {
      setIsBusy(true);

      let changedInfo = {
        name: userInfo.name,
        surname: userInfo.surname,
        gender: userInfo.gender,
        birthDate: userInfo.birthDate ? userInfo.birthDate : null,
        communicationAgreed: isCommunicationApproved
      } as UpdateInfoRequest;

      await customersClient.changeInfo(userInfo.id, changedInfo).then((response) => {
        getCustomerInfo();
        toggleButton();
      });
    }
    catch (err: any) {
      console.log(err);
      if (err.response && err.response.status) {
        setIsAlertOpen(true);
      }
    }
    finally {
      setIsBusy(false);
    }
  }

  const toggleButton = () => {
    !fieldsDisabled && getCustomerInfo();
    setFieldsDisabled(!fieldsDisabled)
    isAlertOpen && setIsAlertOpen(false);
  }

  const sendPhoneConfirmation = () => {
    setOpenConfirmPhone(true);

    customersClient.sendPhoneConfirmation().then((response) => {
      setIsSmsSent(true);
      dispatch(waitPhoneConfirmation());
      var lastPressedTime = new Date().getTime().toString();
      setLastPhoneConfirmTime(lastPressedTime);
      activateTimeout(SendType.Sms);
    })
      .catch((err) => {
        setIsSmsSent(false);
        console.log(err);
      });
  }

  const sendEmailConfirmation = () => {
    customersClient.sendEmailConfirmation().then((response) => {
      setIsEmailSent(true);
      setIsEmailSent(true);
      var lastPressedTime = new Date().getTime().toString();
      setLastEmailConfirm(lastPressedTime);
      activateTimeout(SendType.Mail);
    })
      .catch((err) => {
        setIsEmailSent(false);
        console.log(err);
      });
  }

  const countTimeout = (setAction: React.Dispatch<SetStateAction<boolean>>, getLastTime: () => string, removeItem: () => void) => {
    setAction(true);

    var last = parseInt(getLastTime());
    var mainLoopId = setInterval(function () {
      var now = new Date().getTime();
      var seconds = (now - last) / 1000;

      if (seconds > 10) {
        console.log("finished")
        setAction(false);
        removeItem();
        clearInterval(mainLoopId);
      }

    }, 1000);
  }

  const activateTimeout = (type: SendType) => {
    switch (type) {
      case SendType.Sms:
        countTimeout(setIsSmsSent, getLastPhoneConfirmTime, removeLastPhoneConfirm);
        break;
      case SendType.Mail:
        countTimeout(setIsEmailSent, getLastEmailConfirm, removeLastEmailConfirm);
        break;
    }
  }

  const closeChangePassword = () => {
    setOpenChangePassword(false);
  }
  const closeChangeEmail = () => {
    setOpenChangeEmail(false);
  }
  const closeChangeMobile = () => {
    setOpenChangeMobile(false);
  }
  const closeConfirmPhone = () => {
    setOpenConfirmPhone(false);
  }

  const closeCustomerSwitch = () => {
    setOpenCustomerSwitch(false);
  }

  const closeCustomerDelete = () => {
    setOpenCustomerDelete(false);
  }

  const getStatusIcon = (condition: boolean) => {
    return condition ? <CheckCircleIcon sx={{ color: "paulini.green" }} /> : <CancelIcon sx={{ color: "paulini.error" }} />
  }

  const revokeExternalLogin = (externalLoginType: ExternalAuthenticationType) => {
    setRevokeExternalLoginType(externalLoginType);
    setOpenRevokeLogin(true);
  }

  const closeRevokeExternalLogin = () => {
    setOpenRevokeLogin(false);
    setRevokeExternalLoginType(undefined);
  }

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('settings')} />
      {!userInfo && (<Linear />)}
      <BreadcrumbsBar paths={[{ path: AccountSettingsRoute }]} />
      {userInfo &&
        (<Box sx={{ width: "100%", maxWidth: "1000px", pb: "20px", backgroundColor: "white", borderRadius: appStyleMode !== AppStyle.Mobile ? "15px" : "0px" }}>
          <AccSettingsList isMobile={appStyleMode === AppStyle.Mobile} className={appStyleMode === AppStyle.Mobile && "account-settings"}>
            <InputField fieldName="name" fieldsDisabled={fieldsDisabled} inputValue={userInfo?.name} icon={<CreateIcon />} handleFieldChange={handleFieldChange} />
            <InputField fieldName="surname" fieldsDisabled={fieldsDisabled} inputValue={userInfo?.surname} icon={<CreateIcon />} handleFieldChange={handleFieldChange} />

            <InputField fieldName="gender" fieldsDisabled={fieldsDisabled} inputValue={getGender(userInfo?.gender)} icon={<WcIcon />} handleFieldChange={handleFieldChange} selectComp=
              {genderItems.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {getGenderLabel(option)}
                </MenuItem>
              ))} isGender={true} />

            <InputField fieldName="birthDate" isDate={true} fieldsDisabled={fieldsDisabled} inputValue={userInfo?.birthDate && userInfo.birthDate.format("YYYY-MM-DD")} icon={<CakeIcon />} handleFieldChange={handleFieldChange} />

            <InputField open={() => setOpenChangeEmail(true)} fieldsDisabled={fieldsDisabled} inputValue={clientInfo?.email} icon={<EmailIcon />} isReadOnly={true} endAdornment={
              <InputAdornment position="end">
                {getStatusIcon(clientInfo?.emailConfirmed)}
              </InputAdornment>} additionalComp={
                <>
                  {!clientInfo?.emailConfirmed &&
                    <Grid>
                      {isEmailSent ?
                        (<Typography sx={{ opacity: 0.8 }}>{t('sentEmailConfirmation')}</Typography>) :
                        (<PurpleButton startIcon={<SendIcon />} onClick={() => sendEmailConfirmation()}>{t('sendConfirmation')}</PurpleButton>)}
                    </Grid>}
                </>
              } />

            <InputField open={() => setOpenChangeMobile(true)} fieldsDisabled={fieldsDisabled} inputValue={clientInfo?.phone} icon={<PhoneAndroidIcon />} isReadOnly={true} endAdornment={
              <InputAdornment position="end">
                {getStatusIcon(clientInfo?.phoneConfirmed)}
              </InputAdornment>
            } additionalComp={
              <>
                {!clientInfo?.phoneConfirmed &&
                  (<Grid>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      {isSmsSent ?
                        (<Typography sx={{ opacity: 0.8, color: "black" }}>{t('sentSmsConfirmation')}</Typography>) :
                        (<PurpleButton startIcon={<SendIcon />} onClick={() => sendPhoneConfirmation()}>{t('sendConfirmation')}</PurpleButton>)}
                    </Grid>
                  </Grid>)}
              </>
            } />
            {clientInfo.isLinkedWithFacebook && (
              <InputField
                fieldName="linkedWithFacebook"
                fieldsDisabled={true}
                inputValue={t('linkedWithFacebook')}
                icon={<FacebookIcon sx={{ color: "#4c69ba" }} />}
                endAdornment={fieldsDisabled && (
                  <InputAdornment position="end">
                    <Tooltip title={t('revokeExternalLogin')}>
                      <RevokeIcon onClick={() => revokeExternalLogin(ExternalAuthenticationType.Facebook)} />
                    </Tooltip>
                  </InputAdornment>)
                }
              />)}
            {clientInfo.isLinkedWithGoogle && (
              <InputField
                fieldName="linkedWithGoogle"
                fieldsDisabled={true}
                inputValue={t('linkedWithGoogle')}
                icon={<Icon><img src={GoogleIcon} alt="Google" /></Icon>}
                endAdornment={fieldsDisabled && (
                  <InputAdornment position="end">
                    <Tooltip title={t('revokeExternalLogin')}>
                      <RevokeIcon onClick={() => revokeExternalLogin(ExternalAuthenticationType.Google)} />
                    </Tooltip>
                  </InputAdornment>)
                } />)}
            {clientInfo.isLinkedWithApple && (
              <InputField
                fieldName="linkedWithApple"
                fieldsDisabled={true}
                inputValue={t('linkedWithApple')}
                icon={<AppleIcon sx={{ color: "black" }} />}
                endAdornment={fieldsDisabled && (
                  <InputAdornment position="end">
                    <Tooltip title={t('revokeExternalLogin')}>
                      <RevokeIcon onClick={() => revokeExternalLogin(ExternalAuthenticationType.Apple)} />
                    </Tooltip>
                  </InputAdornment>)
                } />)}

            <ListItem>
              <ListItemIcon>
                <CheckBox isChecked={isCommunicationApproved} onChange={toggleApproveCommunication} />
              </ListItemIcon>
              <Typography color={fieldsDisabled ? "text.disabled" : "common.black"} onClick={toggleApproveCommunication}>{t('communicationApproved')}
              </Typography>
            </ListItem>

            {fieldsDisabled &&
              <CenterListItem>
                <ChangeInfoButton sx={{ width: "210px" }} onClick={() => routeNavigator.goTo(AddressBookRoute)} variant="contained">
                  {t('addressBook')}
                </ChangeInfoButton>
              </CenterListItem>
            }
            <ListItem>
              {fieldsDisabled ?
                (<Grid container justifyContent="center" alignItems="center">
                  <ChangeInfoButton sx={{ width: "210px" }} variant="contained" onClick={() => toggleButton()}>
                    {t('changeInfo')}
                  </ChangeInfoButton>
                </Grid>) :
                (<Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <SaveButton startIcon={<DoneIcon />} variant="contained" onClick={() => changeInfo()}>{t('save')}</SaveButton>
                  <CancelButton startIcon={<ClearIcon />} variant="contained" onClick={() => toggleButton()}>{t('cancel')}</CancelButton>
                </Grid>)
              }
            </ListItem>

            {fieldsDisabled &&
              <CenterListItem>
                <ChangeInfoButton sx={{ width: "210px" }} onClick={() => setOpenChangePassword(true)} variant="contained">
                  {t('changePassword')}
                </ChangeInfoButton>
              </CenterListItem>
            }

            {fieldsDisabled && clientInfo.customers.length > 1 && (
              <CenterListItem>
                <ChangeInfoButton onClick={() => setOpenCustomerSwitch(true)} variant="contained">
                  {t('changeCustomer')}
                </ChangeInfoButton>
              </CenterListItem>
            )}
            {!fieldsDisabled &&
              <CenterListItem>
                <RedButton onClick={() => setOpenCustomerDelete(true)} variant="contained">
                  {t('deleteAccount')}
                </RedButton>
              </CenterListItem>}
          </AccSettingsList>
        </Box>
        )
      }

      <CustomerDelete open={openCustomerDelete} onClose={closeCustomerDelete} />
      <PasswordChange open={openChangePassword} onClose={closeChangePassword} />
      <EmailChange open={openChangeEmail} onClose={closeChangeEmail} />
      <MobileChange open={openChangeMobile} onClose={closeChangeMobile} />
      <ConfirmPhone open={openConfirmPhone} onClose={closeConfirmPhone} />
      <CustomerSwitch open={openCustomerSwitch} onClose={closeCustomerSwitch} />
      <ExternalLoginRevoke externalAuthType={revokeExternalLoginType} open={openRevokeLogin} onClose={closeRevokeExternalLogin} />
      <Spinner isActive={isBusy} />
    </Grid>
  );
}