import { useState, useEffect } from 'react';
import { AppBar, Tabs, Box } from '@mui/material';
import { SubCategories } from "../subCategories/subCategories";
import { ServiceCategory } from 'orderme-api-integration-client';
import { ServiceClient } from "../../helpers/client";
import { RootState } from '../../redux/store';
import { ScrollIconButton, ServiceTab } from "./serviceTabStyles";
import { LastTab, setTabValue } from '../../redux/reducers/tabsReducer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { apiKey, imagesUri } from '../../helpers/clientConfigs';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useSwipeable } from 'react-swipeable';
import { GoodsList } from '../goods/goodsList';
import { isGoodsCategory } from '../../helpers/servicesHelper';

export function ServiceTabs() {
  const dispatch = useAppDispatch();
  const servicesClient: ServiceClient = new ServiceClient();
  const lastTab: LastTab = useAppSelector((state: RootState) => state.tabState);
  const customerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
  const [value, setValue] = useState(lastTab.tabValue);
  const [serviceCategories, setServiceCategories] = useState<ServiceCategory[]>();
  const [goodsCategory, setGoodsCategory] = useState<ServiceCategory>();

  useEffect(() => {
    serviceCategoriesFetcher();
  }, [customerInfo])

  useEffect(() => {
    setGoodsCategory(serviceCategories?.find(isGoodsCategory));
  }, [serviceCategories])

  const serviceCategoriesFetcher = () => {
    servicesClient.getCategories().then((response) => {
      return setServiceCategories(response);
    }).catch((err) => console.log(err));
  }

  const handleTabChange = (newValue: number) => {
    if (newValue > -1 && newValue < serviceCategories?.length) {
      dispatch(setTabValue(newValue));
      setValue(newValue);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleTabChange(value + 1),
    onSwipedRight: () => handleTabChange(value - 1),
  });

  const toggleScrollButtons = (props: any) => {
    if (props.direction === "left") {
      return (
        <ScrollIconButton disableRipple {...props} sx={{ marginRight: "4px", }} >
          <ArrowBackIosIcon />
        </ScrollIconButton>
      );
    } else if (props.direction === "right") {
      return (
        <ScrollIconButton disableRipple {...props} sx={{ marginLeft: "2px", }}>
          <ArrowForwardIosIcon />
        </ScrollIconButton>
      );
    } else {
      return null;
    }
  }

  return (
    <Box sx={{ width: '100%', backgroundColor: 'background.paper', }}>
      <AppBar sx={{ backgroundColor: "paulini.blue", height: "60px" }}
        elevation={0} position="static" color="default">
        <Tabs sx={{ overflow: "auto", pl: "9px", pr: "7px", }}
          scrollButtons
          allowScrollButtonsMobile
          value={value}
          onChange={(_, index) => handleTabChange(index)}
          variant="scrollable"
          TabIndicatorProps={{ sx: { backgroundColor: "paulini.orange", height: "3px" } }}
          ScrollButtonComponent={(props) => toggleScrollButtons(props)}>
          {(serviceCategories)?.map((category, index) => {
            return (
              <ServiceTab key={index}
                label={
                  <img height={30} width={35} src={`${imagesUri}/${category.logoId}?orderme-api-key=${apiKey}&width=35`} alt={category.name} />
                } />
            );
          })}
        </Tabs>
      </AppBar>
      {serviceCategories?.length > 0 &&
        (<Box key={value} {...handlers}>
          {serviceCategories[value] &&
            goodsCategory !== serviceCategories[value] ?
            (<SubCategories category={serviceCategories[value]} tabIndex={value} />) :
            (<GoodsList />)
          }
        </Box>)
      }
    </Box >
  );
}