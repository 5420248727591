import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { ImageLoader } from '../imgLoader/imgLoader';
import { apiKey, imagesUri } from '../../helpers/clientConfigs';
import { ImageNavPrev } from './imageNavPrev';
import { ImageNavNext } from './imageNavNext';
import { useSwipeable } from 'react-swipeable';
interface ImagesCarouselProps {
    images: string[];
    visibleItems: number;
    itemWidth: number;
    selectedItem: number;
    onImageClick?: (index: number) => void;
}

export function ImagesCarousel(props: ImagesCarouselProps) {
    const { images, itemWidth: width, selectedItem } = props;
    const [currentIndex, setCurrentIndex] = useState(0);
    const visibleItems = Math.min(images.length, props.visibleItems);

    useEffect(() => {
        if (selectedItem < images.length - visibleItems + 1)
            setCurrentIndex(selectedItem);
        else
            setCurrentIndex(images.length - visibleItems);
    }, [selectedItem]);

    const next = () => {
        if (currentIndex < images.length - visibleItems) {
            setCurrentIndex(currentIndex + 1);
        }
    };
    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };
    const canGoNext = () => currentIndex < images.length - visibleItems;
    const canGoPrev = () => currentIndex > 0;

    const imageSwipeHandler = useSwipeable({
        onSwipedLeft: () => next(),
        onSwipedRight: () => prev(),
    });

    return (
        <Box sx={{ my: "10px" }} display="flex" alignItems="center" justifyContent="center">
            <Box
                display="flex"
                overflow="hidden"
                mx={2}
                position="relative">
                <ImageNavPrev visible={canGoPrev()} onClick={prev} />
                <ImageNavNext visible={canGoNext()} onClick={next} />
                <Box
                    {...imageSwipeHandler}
                    sx={{
                        display: "flex",
                        width: `${visibleItems * width}px`,
                        transition: "transform 0.5s ease-in-out",
                        transform: `translateX(-${currentIndex * (width)}px)`
                    }}
                >
                    {images.map((image, index) => (
                        <Box key={index} flex={`0 0 ${width}px`} maxWidth={`${width}px`}>
                            <Box
                                sx={{
                                    p: "5px",
                                    borderColor: "paulini.orange",
                                    borderWidth: selectedItem === index ? '1px' : 0,
                                    borderStyle: "solid",
                                    borderRadius: "5px",
                                    my: "1px"
                                }}
                                onClick={() => props.onImageClick(index)}>
                                <ImageLoader
                                    src={`${imagesUri}/${image}?orderme-api-key=${apiKey}`}
                                    alt={image}
                                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};