import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import AuthRoute from '../authRoute';
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { reloadTokenFromStorage } from "../redux/reducers/authReducer"
import { useEffect } from "react"
import { TokenStorage } from "../helpers/tokenStorage"
import { RootState } from "../redux/store"
import { WebsiteRoutes } from "./allRoutes";

function PauliniRoutes() {
    var dispatch = useAppDispatch();
    const location = useLocation();
    const accessToken = useAppSelector((state: RootState) => state.authState.accessToken);

    useEffect(() => {
        if (accessToken === TokenStorage.Instance.getAccessToken())
            return;

        dispatch(reloadTokenFromStorage());
    }, [location, accessToken]);

    return (
        <Routes>
            {
                WebsiteRoutes.map((route, routeIndex) => (
                    route.getAllPaths().map((path, pathIndex) => {
                        let element = route.element;
                        if (route.authType) {
                            element = (
                                <AuthRoute type={route.authType} approved={route.approvedUsers}>
                                    {route.element}
                                </AuthRoute>)
                        }

                        return (
                            <Route
                                key={`Key${routeIndex}-${pathIndex}`}
                                path={path}
                                element={element} />
                        );
                    }
                    )))
            }
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}
export default PauliniRoutes;