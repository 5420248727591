import { createRef, useEffect, useState } from "react";
import { Grid, Typography, List } from "@mui/material";
import { CustomerInfo, Order, SalesPoint } from "orderme-api-integration-client";
import { OrderClient, SalePointClient } from "../../helpers/client"
import { NavBar } from "../appBars/navBar/navBar"
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { HistoryIconStyled, NoOrdersText, PagingButton } from "./orderHistoryStyles"
import { Linear } from "../progressBars/linear"
import Skeleton from '@mui/lab/Skeleton';
import { OrderHistoryItem } from "./orderHistoryItem"
import { RootState } from "../../redux/store";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../redux/hooks";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { OrangeButton } from "../../controls/button/buttonStyles";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { RouterParam } from "../../helpers/routerParam";
import useGetParameter from "../../hooks/useGetParameter";
import { useRouteNavigator } from "../../routes/useRouteNavigator";
import { OrderHistoryRoute } from "../../routes/allRoutes";

export function OrderHistory() {
  const routeNavigator = useRouteNavigator();
  const ordersClient: OrderClient = new OrderClient();
  const salePointClient: SalePointClient = new SalePointClient();
  const [orderHistory, setOrderHistory] = useState<Order[]>([] as Order[]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [points, setPoints] = useState<SalesPoint[]>();
  const userInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
  const listRef = createRef<any>();
  const { t } = useTranslation('order');
  const page = useGetParameter(RouterParam.Page);

  useEffect(() => {
    getPoints();
  }, []);

  useEffect(() => {
    var pageNo = Number(page);
    if (isNaN(pageNo) || pageNo < 1)
      pageNo = 1;

    setPageNumber(pageNo);
    if (userInfo)
      loadOrderHistory(pageNo);
    else {
      setOrderHistory([]);
      setIsLoading(false);
    }
  }, [page, userInfo]);

  useEffect(() => {
    if (orderHistory.length > 0) {
      if (appStyleMode === AppStyle.Mobile) {
        listRef?.current?.scrollTo({ behavior: "smooth", top: 0 });
      }
      else {
        window.scrollTo({ behavior: "smooth", top: 0 });
      }
    }
  }, [orderHistory]);

  const loadOrderHistory = (pageNo: number = 1) => {
    setIsLoading(true);
    ordersClient.get(userInfo.id, pageNo).then((response) => {
      setHasNextPage(response.hasNextPage);
      setOrderHistory(response.items);
      setIsLoading(false);
    })
      .catch((err) => {
        console.log(err);
      });
  }

  const getPoints = () => {
    salePointClient.getAll().then(response => {
      setPoints(response);
    }).catch(err => console.log(err));
  }

  const getPointById = (pointId: string) => {
    if (!pointId) {
      return;
    }

    var point = points?.find(p => p.id === pointId);
    return point;
  }

  const showOrderDetails = (order: Order) => {
    routeNavigator.goToOrderDetail(order.id);
  }

  const hasOnePage = () => {
    return !hasNextPage && pageNumber === 1;
  }

  const showPreviousOrders = () => {
    if (pageNumber > 1)
      routeNavigator.goToOrderHistory(pageNumber - 1);
  }

  const showNextOrders = () => {
    if (hasNextPage)
      routeNavigator.goToOrderHistory(pageNumber + 1);
  }

  const containerStyle = {
    maxWidth: "1000px",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "15px"
  } as const;

  const getOrderHistory = () => {
    if (orderHistory.length > 0) {
      return (
        <Grid sx={containerStyle}>
          <List ref={listRef} className={appStyleMode === AppStyle.Mobile && "orders-history-list"}>
            {orderHistory.map((order, index) => {
              return (
                <Grid container justifyContent="center" key={index}>
                  <OrderHistoryItem order={order} open={showOrderDetails} destination={getPointById(order?.destination?.knownId)} />
                </Grid>);
            })}
            {!hasOnePage() && (
              <Grid sx={{ marginBottom: "10px", textAlign: "center" }}>
                {switchPageView()}
              </Grid>
            )}
          </List>
        </Grid>
      );
    }

    return noOrders()
  }

  const noOrders = () => {
    return (
      <Grid sx={containerStyle} container alignItems="center" direction="column">
        <NoOrdersText>{t('noOrdersYet')}</NoOrdersText>
        <OrangeButton variant="contained" onClick={() => routeNavigator.goToSearch()}>{t('createOrder')}</OrangeButton>
        <HistoryIconStyled />
      </Grid>
    );
  }

  const switchPageView = () => {
    return (
      <Grid sx={{ verticalAlign: "center" }}>
        <PagingButton onClick={showPreviousOrders}
          sx={{ backgroundColor: "paulini.orange", color: "white", marginRight: "2px", visibility: pageNumber !== 1 ? "visible" : "hidden" }}>
          <ArrowBackIcon />
        </PagingButton>
        <Typography sx={{ margin: "0px 5px", fontSize: "20px", display: "inline-block", color: "black" }}>{pageNumber}</Typography>
        <PagingButton onClick={showNextOrders}
          sx={{ visibility: hasNextPage ? "visible" : "hidden" }}>
          <ArrowForwardIcon />
        </PagingButton>
      </Grid>
    );
  }

  const loading = () => {
    return (
      <>
        <Linear />
        <BreadcrumbsBar paths={[{ path: OrderHistoryRoute }]} />
        <Grid sx={containerStyle} container direction="column" alignItems="center">
          <Skeleton sx={{ height: "50px", width: "50%" }} />
          {[...Array(4)].map((_, index) => <Skeleton sx={{ height: "90px", width: "90%" }} key={index} animation="wave" />)}
        </Grid>
      </>
    );
  }

  return (
    <Grid container direction="column" alignItems="center" sx={{ overflow: "hidden" }}>
      <NavBar barTitle={t('history')} />
      {isLoading ? (loading()) :
        (<>
          <BreadcrumbsBar paths={[{ path: OrderHistoryRoute }]} />
          {getOrderHistory()}
        </>)}
      {appStyleMode === AppStyle.Mobile && <BottomCartBar />}
    </Grid>
  );
}