import { useEffect, useState } from "react";
import { Box, Divider, Grid, IconButton, styled, useMediaQuery } from "@mui/material";
import { ServiceClient } from "../../helpers/client";
import { CustomerInfo, ServiceCategory, ServiceGroup, ShortDataLinkType } from "orderme-api-integration-client";
import { useLocation, useParams } from "react-router";
import { ServiceLogoItem } from "../serviceLogoItem/serviceLogoItem"
import { NoElements } from "../subCategories/noElements";
import { ServicesList } from "../selectedService/servicesList";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { StickyCartBar } from "../appBars/cartBar/stickyCartBar";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import useGetParameter from "../../hooks/useGetParameter";
import { RouterParam } from "../../helpers/routerParam";
import { useRouteNavigator } from "../../routes/useRouteNavigator";
import { CategoryGroupRoute, CategoryRoute } from "../../routes/allRoutes";
import { generateSlug } from "../../helpers/slugGenerator";

const ImagesGrid = styled(Grid)(({ theme }) => ({
    maxWidth: "420px",
    [theme.breakpoints.up(1300)]: {
        width: "820px",
        minWidth: "820px"
    },
    '@media (max-width: 740px)': {
        justifyContent: 'center'
    }
}));

export function SelectedCategory() {
    const location = useLocation();
    const { categoryId: categoryIdParam } = useParams();
    const { category: categoryName } = useParams();
    const { group: groupName } = useParams();
    const groupIdParam = useGetParameter(RouterParam.Group);

    const [categoryId, setCategoryId] = useState<string>();
    const [groupId, setGroupId] = useState<string>(null);
    const [category, setCategory] = useState<ServiceCategory>();
    const [categoryGroups, setCategoryGroups] = useState<ServiceGroup[]>(null);
    const [currentGroup, setCurrentGroup] = useState<ServiceGroup>();
    const servicesClient: ServiceClient = new ServiceClient();
    const useTwoColumns = useMediaQuery('(min-width:900px)');
    const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
    const routeNavigator = useRouteNavigator();
    const categoryLinks = useAppSelector((state: RootState) => state.linksState.categories);
    const groupLinks = useAppSelector((state: RootState) => state.linksState.groups);

    useEffect(() => {
        setCategoryGroups(null);
        if (categoryIdParam) {
            const link = categoryLinks.find(link => link.id === categoryIdParam);
            if (link) {
                setCategoryId(link.dataId);
            }
            else {
                routeNavigator.goHome();
            }
        }
        else {
            setCategoryId(null);
            return;
        }
    }, [categoryIdParam])

    useEffect(() => {
        var groupId: string;
        if (groupIdParam) {
            const link = groupLinks.find(link => link.id === groupIdParam);
            if (link)
                groupId = link.dataId;
        }

        setGroupId(groupId ?? null);
    }, [groupIdParam])

    useEffect(() => {
        if (!categoryId) {
            setCategoryGroups(null);
            setCategory(null);
            return;
        }

        servicesClient.getCategories().then((response) => {
            const foundCategory = response.find((element) => element.id === categoryId);
            if (!foundCategory)
                routeNavigator.goHome();

            var currentName = generateSlug(foundCategory.name);
            if (categoryName !== currentName) {
                const newPath = location.pathname.replace(categoryName, currentName);
                window.history.replaceState(null, '', newPath);
            }

            setCategory(foundCategory);
            getCategoryGroups(foundCategory);
        }).catch((err) => {
            setCategory(null);
            console.log(err);
        });
    }, [categoryId, customerInfo])

    useEffect(() => {
        window.scrollTo({ behavior: "smooth", top: 0 });
        if (categoryGroups?.length > 0) {
            if (groupId) {
                const group = categoryGroups.find((group) => group.id === groupId);
                if (group) {
                    setCurrentGroup(group);
                    openServicesPage(group, true);
                    return;
                }
            }

            if (useTwoColumns) {
                openServicesPage(categoryGroups[0], true);
                return;
            }
        }

        setCurrentGroup(null);
    }, [groupId, categoryGroups])

    useEffect(() => {
        if (currentGroup)
            openServicesPage(currentGroup, true);
    }, [currentGroup])

    function getCategoryGroups(category: ServiceCategory) {
        servicesClient.getCategoryGroups(category.id, customerInfo.id)
            .then((response) => {
                setCategoryGroups(response);
            })
            .catch((err) => {
                setCategoryGroups(null);
                console.log(err);
            });
    }

    const openServicesPage = (serviceGroup: ServiceGroup, replace?: boolean) => {
        if (groupId !== serviceGroup.id || generateSlug(serviceGroup.name) !== groupName)
            routeNavigator.goToCategoryGroup(category, serviceGroup, replace);
    }

    const navigateToCategory = () => {
        routeNavigator.goToCategory(category);
    };

    return (
        <Box sx={{ mt: "10px" }}>
            {currentGroup ?
                <BreadcrumbsBar
                    paths={[
                        {
                            path: CategoryRoute,
                            title: category?.name,
                            isLink: !useTwoColumns,
                            customPath: category ? routeNavigator.getCategoryPath(category) : null
                        },
                        {
                            path: CategoryGroupRoute,
                            title: currentGroup.name
                        }
                    ]} />
                : <BreadcrumbsBar paths={[{ path: CategoryRoute, title: category?.name }]} />
            }
            {categoryGroups && categoryGroups.length === 0 && <NoElements />}
            {categoryGroups && categoryGroups.length > 0 &&
                (<Box sx={{
                    display: "flex",
                    maxWidth: !useTwoColumns ? "410px" : null,
                    flexDirection: useTwoColumns ? "row" : "column",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    p: useTwoColumns ? "10px" : "0px",
                    width: "auto",
                    mx: !useTwoColumns ? "auto" : null
                }}>
                    <ImagesGrid item container alignContent="flex-start" >
                        {
                            categoryGroups.map((service, index) => (
                                <Box key={service.id} sx={{
                                    p: "10px",
                                    backgroundColor: "white",
                                    borderRadius: "15px",
                                    m: service === currentGroup ? "2px" : "5px",
                                    borderStyle: service === currentGroup ? "solid" : null,
                                    borderColor: "paulini.orange",
                                    borderWidth: "3px",
                                    display: !useTwoColumns && currentGroup ? "none" : null,
                                    width: "380px",
                                    height: "250px"
                                }}>
                                    <ServiceLogoItem
                                        key={index}
                                        service={service}
                                        isButton={true}
                                        open={() => openServicesPage(service)} />
                                </Box>
                            ))}
                    </ImagesGrid>
                    <Divider orientation="vertical" flexItem
                        sx={{
                            borderRightWidth: "5px",
                            width: "10px",
                            display: !useTwoColumns ? "none" : null
                        }} />
                    <Box sx={{ minWidth: "0px", width: "100%" }}>
                        {currentGroup && (<>
                            {!useTwoColumns && (
                                <Box sx={{ position: "relative", height: "250px" }}>
                                    <IconButton
                                        sx={{ position: "absolute", mt: "5px", zIndex: 2 }}
                                        onClick={navigateToCategory}>
                                        <ArrowBackIcon sx={{ color: "white" }} />
                                    </IconButton>
                                    <ServiceLogoItem service={currentGroup} />
                                </Box>)
                            }
                            <ServicesList serviceGroup={currentGroup} serviceCategory={category}></ServicesList>
                            <StickyCartBar sx={{ mx: "16px", mb: "10px" }} />
                        </>)
                        }
                    </Box>
                </Box >)
            }
        </Box >
    )
}